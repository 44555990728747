import { getBottomSpace, getStatusBarHeight } from "react-native-iphone-x-helper";
import { RFValue } from "react-native-responsive-fontsize";
import styled from "styled-components/native";

export const Container = styled.View`
  flex: 1;
  padding: ${getStatusBarHeight() + 20}px 20px ${getBottomSpace()}px;
  background: ${({ theme }) => theme.colors.background};
`;

export const Image = styled.Image`
  width: 100%;
  flex: 1;
`;

export const SubContainer = styled.View`
  padding-bottom: 40px;
`;

export const ButtonContainer = styled.View`
  margin-top: 32px;
  margin-bottom: 24px;
`;

export const SubTitle = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primaryMedium};
  font-size: ${RFValue(14)}px;
  color: ${({ theme }) => theme.colors.subtitle};
  text-align: center;
`;
