import AsyncStorage from '@react-native-async-storage/async-storage';
import { AxiosError } from 'axios';
import moment from 'moment';
import React, {
  createContext,
  ReactNode,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import { Alert } from 'react-native';
import { api } from '../services/axiosInstances';
import { CompanyViewDto } from '../services/dtos/viewDtos';

interface ProviderProps {
  children: ReactNode;
}

interface UserProps {
  userId: number,
  name: string,
  email: string,
  phone: string;
  birthday: string;
  gender: number;
  profilePhotoId?: string;
  profilePhoto?: string;
  photo?: {
    fileId: string;
    url: string;
  };
  location?: {
    latitude: number;
    longitude: number;
  }
  about?: string;
  menu?: string;
  website?: string;
  companyId: string;
}

interface Props {
  user: UserProps;
  isLoading: boolean;
  SignIn: (value: SignInProps) => void;
  SignOut: () => void;
  updateUser: (value: UserProps) => void;
  company: CompanyViewDto;
  updateCompany: (value: CompanyViewDto) => void;
  isFirstAccess: boolean;
}

interface SignInProps {
  email: string;
  password: string;
  firstAccess?: boolean;
}

const Auth = createContext<Props>({} as Props);

export const AuthProvider = ({ children }: ProviderProps) => {
  const [user, setUser] = useState({} as UserProps);
  const [company, setCompany] = useState({} as CompanyViewDto);
  const [exp, setExp] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isFirstAccess, setIsFirstAccess] = useState(false);

  const updateUser = useCallback((value: UserProps) => {
    setUser(value);
  }, []);

  const updateCompany = useCallback((value: CompanyViewDto) => {
    setCompany(value || {});
  }, []);

  const SignIn = async ({ email, password, firstAccess = false }: SignInProps) => {
    try {
      const { data } = await api.post('/auth/login', { username: email, password, grant_type: 'password' });
      const {
        access_token: tokenApi,
        refresh_token,
        expires_in: expApi,
        token_type,
      } = data;
      api.defaults.headers.common.Authorization = `${token_type} ${tokenApi}`;

      const { data: userData } = await api.get('auth/get-user');
      setExp(data.access_token);
      setUser(userData);
      await AsyncStorage.setItem('@MenuMax:token', JSON.stringify(tokenApi));
      await AsyncStorage.setItem('@MenuMax:refresh_token', JSON.stringify(refresh_token));
      await AsyncStorage.setItem('@MenuMax:exp', JSON.stringify(expApi));
      await AsyncStorage.setItem('@MenuMax:user', JSON.stringify(userData));
      setIsFirstAccess(firstAccess);
    } catch (e) {
      const error = e as AxiosError;
      throw error.response?.data;
    }
  };

  const SignOut = async () => {
    setExp('');
    setUser({} as UserProps);
    setCompany({} as CompanyViewDto);
    await AsyncStorage.multiRemove([
      '@MenuMax:token',
      '@MenuMax:refresh_token',
      '@MenuMax:user',
      '@MenuMax:exp',
    ]);
  };

  const getLoggedStatus = async () => {
    try {
      const storageToken = await AsyncStorage.getItem('@MenuMax:token');
      const storageUser = await AsyncStorage.getItem('@MenuMax:user');
      const storageExp = await AsyncStorage.getItem('@MenuMax:exp');
      if (storageToken && storageUser && storageExp) {
        setUser(JSON.parse(storageUser));
        setExp(JSON.parse(storageExp));
        api.defaults.headers.common.Authorization = `Bearer ${JSON.parse(storageToken)}`;
      }
    } catch {
      throw new Error('Was not possible to get the values');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getLoggedStatus();
  }, []);

  useEffect(() => {
    const persistLogin = async () => {
      try {
        const token = JSON.parse(await AsyncStorage.getItem('@MenuMax:refresh_token') || '');
        const { data: refreshData } = await api.post('/auth/login', {
          grant_type: 'refresh_token',
          refresh_token: token,
        });
        await AsyncStorage.setItem('@MenuMax:token', JSON.stringify(refreshData.access_token));
        await AsyncStorage.setItem('@MenuMax:refresh_token', JSON.stringify(refreshData.refresh_token));
        await AsyncStorage.setItem('@MenuMax:exp', JSON.stringify(refreshData.expires_in));
        api.defaults.headers.common.Authorization = `${refreshData.token_type} ${refreshData.access_token}`;
      } catch {
        SignOut();
      }
    };

    if (moment(new Date()).isAfter(new Date(exp))) {
      persistLogin();
    }
  }, [exp]);

  useEffect(() => {
    if (!company.masterLocation) return;
    if (!company.masterLocation.latitude) Alert.alert('Defina uma localização no perfil');
  }, [company]);

  return (
    <Auth.Provider value={{
      user, isLoading, SignIn, SignOut, updateUser, updateCompany, company, isFirstAccess,
    }}
    >
      {children}
    </Auth.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(Auth);
  if (!context) throw new Error('Need to use Provider!');
  return context;
};
