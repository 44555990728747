import React, { useMemo, useState } from 'react';
import {
  getMediaLibraryPermissionsAsync,
  requestMediaLibraryPermissionsAsync,
  launchImageLibraryAsync,
} from 'expo-image-picker';
import { Image } from 'react-native';
import { ImageInfo } from 'expo-image-picker/build/ImagePicker.types';
import { Button } from '../../../../components/Button';
import { AnimatedPlaceholderInput } from '../../../../components/inputs/AnimatedPlaceholderInput';
import { BaseInput } from '../../../../components/inputs/BaseInput';

import ImagePickerIcon from '../../../../assets/svg/imagePickerIcon.svg';

import {
  ButtonContainer, DescriptionContainer, ImagePicker, ImagePickerTitle, Title,
} from './styles';
import { fileService, FileTypeEnum } from '../../../../services/fileService';
import { api } from '../../../../services/axiosInstances';

interface Props {
  titleValue: string;
  descriptionValue: string;
  setTitleValue: (value: string) => void;
  setDescriptionValue: (value: string) => void;
  handleSteps: () => void;
  adType: string;
  image?: ImageInfo;
  setImage: (value: ImageInfo) => void;
}

const BasicData = ({
  titleValue,
  setTitleValue,
  setDescriptionValue,
  descriptionValue,
  handleSteps,
  adType,
  setImage,
  image,
}: Props) => {
  const handleImage = async () => {
    const { granted } = await getMediaLibraryPermissionsAsync();
    if (!granted) {
      await requestMediaLibraryPermissionsAsync();
    }
    const data = await launchImageLibraryAsync({
      quality: 1,
    });
    if (!data.cancelled) {
      setImage(data);
    }
  };

  const isInactive = useMemo(() => (adType === 'banner' ? !image || !titleValue || !descriptionValue : !titleValue || !descriptionValue), [adType, descriptionValue, image, titleValue]);

  return (
    <>
      <Title>{adType === 'push-notification' ? "Qual mensagem você deseja enviar?" : "Escolha a imagem do banner"}</Title>
      {
      adType === 'banner' && (
        <ImagePicker onPress={handleImage}>
          {
            !image
              ? (
                <>
                  <ImagePickerIcon />
                  <ImagePickerTitle />
                </>
              )
              : (
                <Image
                  source={{ uri: image.uri }}
                  resizeMode="cover"
                  style={{ width: '100%', flex: 1 }}
                />
              )
            }
        </ImagePicker>
      )
    }
      <AnimatedPlaceholderInput
        placeholder="Insira título do anúncio"
        animatedValue={titleValue}
        onChangeText={setTitleValue}
      />
      <DescriptionContainer>
        <BaseInput
          placeholder="Descrição"
          value={descriptionValue}
          onChangeText={setDescriptionValue}
        />
      </DescriptionContainer>
      <ButtonContainer>
        <Button title="Começar" isInactive={isInactive} onPress={handleSteps} />
      </ButtonContainer>
    </>
  );
};

export default BasicData;
