import React from "react";
import { CampaignViewDto } from "../../../../services/dtos/viewDtos";
import {
  Block,
  Container, Header, ItemStatusText, ItemText, ItemTextValue, ItemTitle, Status, SubContainer,
} from "./styles";

interface CampaignView {
  campaign: CampaignViewDto
}

export const CampaignView = ({ campaign }:CampaignView) => (
  <Container>
    <Header>
      <ItemStatusText>
        <Status isActive={campaign.statusType.displayName === 'Ativa'} />
        {'  '}
        {campaign.statusType.displayName}
      </ItemStatusText>
      <ItemTitle>
        {campaign.campaignType.displayName}
        {' '}
        |
        {' '}
        {campaign.name}
      </ItemTitle>
    </Header>
    <SubContainer horizontal showsHorizontalScrollIndicator={false}>
      <Block>
        <ItemTextValue>{campaign.estimatedBudget.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</ItemTextValue>
        <ItemText>Valor gasto</ItemText>
      </Block>
      <Block>
        <ItemTextValue>{campaign.actionClick}</ItemTextValue>
        <ItemText>Cliques</ItemText>
      </Block>
      {/* <Block>
        <ItemTextValue>{campaign.actionView}</ItemTextValue>
        <ItemText>Impressões</ItemText>
      </Block> */}
    </SubContainer>
  </Container>
);
