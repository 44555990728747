import { Entypo } from '@expo/vector-icons';
import { RFValue } from 'react-native-responsive-fontsize';
import styled from 'styled-components/native';

export const Container = styled.View``;

export const PeriodContainer = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})`
  flex-direction: row;
  padding: 8px 0 0px 20px;
`;

export const PeriodText = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primaryMedium};
  color: ${({ theme }) => theme.colors.subtitle};
  font-size: ${RFValue(14)}px;
  margin: 0 6px;
`;

export const PeriodIcon = styled(Entypo).attrs({
  name: 'chevron-down',
})`
  color: ${({ theme }) => theme.colors.subtitle};
  font-size: 20px;
`;

export const ItemContainer = styled.View.attrs({
  shadowOffset: {
    height: 0,
    width: 0,
  },
  shadowRadius: 10,
  shadowColor: 'rgba(42, 42, 62, 0.15)',
  shadowOpacity: 1,
  elevation: 5,
})`
  border-radius: 8px;
  padding: 24px 24px 17px 16px;
  background: ${({ theme }) => theme.colors.background};
`;

export const ItemTitle = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primaryRegular};
  color: ${({ theme }) => theme.colors.subtitle};
  font-size: ${RFValue(14)}px;
`;

export const ItemMain = styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

export const ItemValue = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primarySemiBold};
  color: ${({ theme }) => theme.colors.subtitle};
  font-size: ${RFValue(32)}px;
`;

export const Separator = styled.View`
  width: 16px;
`;
