import styled from 'styled-components/native';
import { Dimensions } from 'react-native';
import { getStatusBarHeight } from 'react-native-iphone-x-helper';
import { Feather } from '@expo/vector-icons';
import { RFValue } from 'react-native-responsive-fontsize';
import { LinearGradient } from 'expo-linear-gradient';

const { width } = Dimensions.get('window');

export const Container = styled.Pressable`
  flex: 1;
  background: ${({ theme }) => theme.colors.background};
  padding: ${getStatusBarHeight() + 24}px 20px 0px;
`;

export const SearchIcon = styled(Feather).attrs({
  name: 'search',
})`
  font-size: 24px;
  color: ${({ theme }) => theme.colors.subtitle};
`;

export const HeaderContainer = styled.View`
`;

export const CategoryText = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primarySemiBold};
  font-size: ${RFValue(18)}px;
  color: ${({ theme }) => theme.colors.title};
  margin-bottom: 24px;
`;

export const HeaderText = styled(CategoryText)`
  font-size: ${RFValue(16)}px;
  align-self: center;
`;

export const SectionContainer = styled.View`
  margin-bottom: ${40 - 24}px;
`;

export const ItemsContainer = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const ButtonContainer = styled(LinearGradient).attrs({
  colors: ['rgba(255, 255, 255, 0.7)', 'rgba(255, 255, 255, 1)'],
})`
  height: 111px;
  width: ${width}px;
  padding: 0 20px;
  justify-content: center;
  position: absolute;
  bottom: 0px;
`;
