import React, { useEffect, useState, useCallback } from 'react';
import { ScrollView, StatusBar } from 'react-native';
import { requestForegroundPermissionsAsync } from 'expo-location';
import moment from 'moment';
import { useNavigation } from '@react-navigation/native';
import { useBlockBackButton } from '../../hooks/blockBackButton';

import { Highlights } from './components/Highlights';
import { ActivityModal } from './components/Modal';

import { periodDates } from '../../utils/getHome';

import {
  ActivityContainer,
  ActivityItemContainer,
  ActivityTitle,
  Container,
  HeaderContainer,
  Title,
} from './styles';
import { Acitivity } from './components/Acitivity';
import { api } from '../../services/axiosInstances';
import { apiServices } from '../../services/apiServices';
import { useAuth } from '../../hooks/auth';

interface ActivityProps {
  title: string;
  hour: Date;
  value: number;
  type: 'banner' | 'push-notification';
}

export const Home = () => {
  const [highlightsValues, setHighlightsValues] = useState<number[]>([]);
  const { updateCompany, company } = useAuth();

  const [modalVisibility, setModalVisibility] = useState(false);
  const [activity, setActivity] = useState<ActivityProps[]>([]);
  const [selectedItem, setSelectedItem] = useState(0);
  const { isFirstAccess } = useAuth();
  const navigation = useNavigation();
  const requestLocation = async () => {
    await requestForegroundPermissionsAsync();
  };

  const handlePeriod = useCallback(async (value: number) => {
    let startDate;
    switch (value) {
      case 0:
        startDate = moment().startOf('date');
        break;
      case 1:
        startDate = moment().startOf('date').subtract(1, 'day');
        break;
      case 2:
        startDate = moment().startOf('date').subtract(7, 'days');
        break;
      case 3:
        startDate = moment().startOf('date').subtract(14, 'days');
        break;
      case 4:
        startDate = moment().startOf('date').subtract(30, 'days');
        break;
      default:
        break;
    }
    setSelectedItem(value);
    try {
      const values = [
        {
          name: 'Visualizaram o perfil',
          count: 0,
        },
        {
          name: 'Visualizaram o cardápio',
          count: 0,
        },
        {
          name: 'Cliques no Banner',
          count: 0,
        },
        {
          name: 'Cliques na Notificação',
          count: 0,
        },
      ];
      const { data } = await api.get(`/home/cnpj?startDate=${startDate?.toISOString()}`);
      setHighlightsValues(values
        .map((item) => data.counts.find((element) => element.name === item.name)?.count || 0));
    } catch {
      setHighlightsValues([0, 0, 0, 0]);
    }
  }, []);

  const openModal = useCallback(() => {
    setModalVisibility(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalVisibility(false);
  }, []);

  useEffect(() => {
    requestLocation();
    apiServices.getCurrentCompany().then((company) => {
      if (!company.success) return;

      updateCompany(company.data);
    });
    (async () => {
      const { data } = await api.get<{result: { name: string, campaignType: { code: 'banner' | 'push-notification' }, value: number, dtCreatedAt: string }[]}>('activities');
      setActivity(data.result.reduce((actual, {
        name, campaignType: { code }, value, dtCreatedAt,
      }) => {
        actual.push({
          title: name,
          value,
          type: code,
          hour: dtCreatedAt,
        });

        return actual;
      }, []));
    })();
  }, [updateCompany]);

  useEffect(() => {
    handlePeriod(4);
  }, [handlePeriod]);

  useEffect(() => {
    if (isFirstAccess) {
      navigation.navigate('Profile');
    }
  }, [isFirstAccess, navigation]);

  useBlockBackButton();

  return (
    <Container>
      <StatusBar barStyle="light-content" translucent backgroundColor="transparent" />
      <HeaderContainer>
        <Title>{company.name || 'Seu restaurante'}</Title>
      </HeaderContainer>
      <ScrollView contentContainerStyle={{ paddingVertical: 24 }}>
        <Highlights
          values={highlightsValues}
          onPress={openModal}
          period={periodDates[selectedItem]}
        />
        <ActivityContainer>
          <ActivityTitle>Suas atividades</ActivityTitle>
          {
          activity.map(({
            title, hour, value, type,
          }, index) => (
            <ActivityItemContainer
              key={String(index)}
            >
              <Acitivity
                type={type}
                title={title}
                hour={hour}
                value={value}
              />
            </ActivityItemContainer>
          ))
          }
        </ActivityContainer>
      </ScrollView>
      <ActivityModal
        modalVisibility={modalVisibility}
        handlePeriod={handlePeriod}
        close={closeModal}
        selectedItem={selectedItem}
        periodDates={periodDates}
      />
    </Container>
  );
};
