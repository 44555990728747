import React, { useState, useEffect } from 'react';
import { FlatList } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';

import { BackButton } from '../../../components/BackButton';
import { HelpButton } from '../../../components/HelpButton';

import { DataProps } from '../../../utils/getMainQuestions';

import {
  Container,
  HeaderContainer,
  Separator,
  SubTitle,
} from '../HelpCenter/styles';

export const AboutApp = () => {
  const [data, setData] = useState<DataProps[]>([] as DataProps[]);
  const navigation = useNavigation();
  const { params } = useRoute() as { params:DataProps};

  useEffect(() => {
    setData(params.data || []);
  }, [params.data]);

  const handlePress = (action: string | undefined, propsData?: DataProps) => {
    if (action) navigation.navigate(action, propsData && propsData);
  };

  return (
    <Container>
      <HeaderContainer>
        <BackButton />
        <SubTitle>{params?.title}</SubTitle>
      </HeaderContainer>
      <FlatList
        data={data}
        keyExtractor={(item) => item.title}
        renderItem={({ item }) => (
          <HelpButton
            title={item.title}
            subTitle={item.subTitle}
            hasArrowIcon
            onPress={() => handlePress(item.action, item)}
          />
        )}
        ItemSeparatorComponent={Separator}
      />
    </Container>
  );
};
