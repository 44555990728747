import React, { useEffect, ReactNode, useState } from 'react';
import { TextInputProps } from 'react-native';
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';
import { useTheme } from 'styled-components/native';

import { Container, ErrorText, Input } from './styles';

interface Props extends TextInputProps {
  placeholder: string;
  animatedValue: string;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  error?:string;
  isEnabled?: boolean;
}

export const AnimatedPlaceholderInput = ({
  placeholder,
  leftIcon,
  rightIcon,
  animatedValue,
  error,
  isEnabled = true,
  ...rest
}: Props) => {
  const theme = useTheme();
  const placeholderY = useSharedValue(0);
  const placeholderSize = useSharedValue(14);

  const [isFocused, setFocused] = useState(false);

  const handleFocus = () => {
    placeholderY.value = withTiming(-11);
    placeholderSize.value = withTiming(12);
    setFocused(true);
  };

  const handleBlur = () => {
    if (!animatedValue) {
      placeholderY.value = withTiming(0);
      placeholderSize.value = withTiming(14);
    }
    setFocused(false);
  };

  const inputStyle = useAnimatedStyle(() => ({
    fontFamily: theme.fonts.primaryRegular,
    fontSize: placeholderSize.value,
    color: theme.colors.subtitle,
    position: 'absolute',
    transform: [
      {
        translateY: placeholderY.value,
      },
    ],
  }));

  useEffect(() => {
    if (animatedValue) {
      placeholderY.value = withTiming(-11);
      placeholderSize.value = withTiming(12);
    }
  }, [animatedValue, placeholderSize, placeholderY]);

  return (
    <>
      <Container isFocused={isFocused} isErrored={!!error}>
        {leftIcon && leftIcon}
        <Animated.Text style={[
          inputStyle,
          { paddingHorizontal: leftIcon ? 60 : 20 },
        ]}
        >
          {placeholder}

        </Animated.Text>
        <Input
          hasLeftIcon={!!leftIcon}
          onBlur={handleBlur}
          onFocus={handleFocus}
          keyboardAppearance="dark"
          value={animatedValue}
          selectionColor={theme.colors.primary}
          editable={isEnabled}
          isEnabled={isEnabled}
          {...rest}
        />
        {rightIcon && rightIcon}
      </Container>
      {!!error
      && <ErrorText>{error}</ErrorText>}
    </>
  );
};
