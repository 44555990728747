import React from 'react';
import { TouchableOpacityProps } from 'react-native';
import { Container, Icon } from './styles';

interface Props extends TouchableOpacityProps {
  isActive: boolean;
}

export const CheckBox = ({ isActive = false, ...rest }: Props) => (
  <Container isActive={isActive} {...rest}>
    {isActive && <Icon name="check" />}
  </Container>
);
