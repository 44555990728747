import React, { useState, useEffect } from 'react';
import { FlatList } from 'react-native';

import { periodData } from '../../../../utils/getHome';

import Calendar from '../../../../assets/svg/Calendar.svg';
import Restaurant from '../../../../assets/svg/Restaurant.svg';
import Eye from '../../../../assets/svg/Eye.svg';
import Banner from '../../../../assets/svg/Banner.svg';
import Notification from '../../../../assets/svg/Notification.svg';

import {
  Container,
  Separator,
  PeriodContainer,
  ItemContainer,
  PeriodText,
  PeriodIcon,
  ItemTitle,
  ItemMain,
  ItemValue,
} from './styles';

type IconProps = 'restaurant' | 'eye' | 'banner' | 'notification';
interface DataProps {
  title: string;
  icon: IconProps;
  value: number;
}

interface Props {
  values: number[];
  onPress?: () => void;
  period: string;
}

export const Highlights = ({ values, onPress, period }: Props) => {
  const [data, setData] = useState<DataProps[]>();

  const handleIcon = (name: IconProps) => {
    const icon = {
      restaurant: <Restaurant />,
      eye: <Eye />,
      banner: <Banner />,
      notification: <Notification />,
    };
    return icon[name];
  };

  useEffect(() => {
    (async () => {
      const formatedData: DataProps[] = [];
      periodData.forEach((item, index) => {
        formatedData.push({
          title: item.title,
          icon: item.icon,
          value: values[index] || 0,
        });
      });
      setData(formatedData);
    })();
  }, [values]);

  return (
    <Container>
      <PeriodContainer onPress={onPress}>
        <Calendar />
        <PeriodText>{period}</PeriodText>
        <PeriodIcon />
      </PeriodContainer>
      <FlatList
        horizontal
        contentContainerStyle={{
          paddingHorizontal: 20,
          paddingVertical: 12,
        }}
        showsHorizontalScrollIndicator={false}
        ItemSeparatorComponent={Separator}
        keyExtractor={({ title }) => title}
        data={data}
        renderItem={({ item }) => (
          <ItemContainer>
            <ItemMain>
              <ItemValue>{item.value}</ItemValue>
              {handleIcon(item.icon)}
            </ItemMain>
            <ItemTitle>{item.title}</ItemTitle>
          </ItemContainer>
        )}
      />
    </Container>
  );
};
