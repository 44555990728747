import React from 'react';
import { CardStyleInterpolators, createStackNavigator } from '@react-navigation/stack';

import { HelpCenter } from '../screens/Help/HelpCenter';
import { QuestionHelp } from '../screens/Help/QuestionHelp';
import { AboutApp } from '../screens/Help/AboutApp';
import { ContentHelp } from '../screens/Help/ContentHelp';

const { Navigator, Screen } = createStackNavigator();

export const HelpRoutes = () => (
  <Navigator
    screenOptions={{
      headerShown: false,
      cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
      detachPreviousScreen: false,
    }}
  >

    <Screen name="HelpCenter" component={HelpCenter} />
    <Screen name="QuestionHelp" component={QuestionHelp} />
    <Screen name="AboutApp" component={AboutApp} />
    <Screen name="ContentHelp" component={ContentHelp} />
  </Navigator>
);
