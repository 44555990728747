import React, { useCallback, useState } from 'react';
import { ImageInfo } from 'expo-image-picker/build/ImagePicker.types';
import { useNavigation } from '@react-navigation/native';
import { Alert } from 'react-native';
import BasicDataIcon from '../../../../assets/svg/BasicDataIcon.svg';
import PublicIcon from '../../../../assets/svg/publicIcon.svg';
import AccountingIcon from '../../../../assets/svg/AccountingIcon.svg';
import { Ivalues } from '../..';

import {
  BoxContainer, ButtonContainer, Content, FieldText, FieldTitle, Title, TitleContainer,
} from './styles';
import { Button } from '../../../../components/Button';
import { fileService, FileTypeEnum } from '../../../../services/fileService';
import { apiServices } from '../../../../services/apiServices';
import { parseValidationErrorsToString } from '../../../../services/utils/serviceUtils';

interface Props {
  data: Ivalues;
  blocks: Step[];
}

type Step = 'Dados básicos' | 'Público' | 'Orçamento'

const renderIcon = (item: Step) => {
  const elements = {
    'Dados básicos': <BasicDataIcon />,
    Público: <PublicIcon />,
    Orçamento: <AccountingIcon />,
  };
  return elements[item];
};

export const Review = ({
  data, blocks,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigation = useNavigation();
  const handleArray = (values: string[]) => {
    const response = values ? values.join(", ") : '';
    return (response);
  };

  const handleFinish = useCallback(async () => {
    const request = data;
    let fileId : string | undefined;
    try {
      setIsLoading(true);
      if (request.campaignTypeCode === 'banner') {
        const fileResult = await fileService.sendFile(
        data.image as ImageInfo,
        FileTypeEnum.Banner,
        );

        if (fileResult.success === false) {
          Alert.alert("Erro ao realizar upload do arquivo, tente novamente");
          setIsLoading(false);
          return;
        }
        fileId = fileResult.data.fileId;
      }

      const createCampaignResponse = await apiServices.createCampaign({
        campaignTypeCode: request.campaignTypeCode,
        description: request.description,
        startDate: request.dateRange.startDate,
        endDate: request.dateRange.endDate,
        distance: request.range,
        fileId: fileId ?? null,
        name: request.name,
        title: request.title,
      });

      if (createCampaignResponse.success === false) {
        Alert.alert(createCampaignResponse.errorMessage
          ? parseValidationErrorsToString(createCampaignResponse.errorMessage)
          : createCampaignResponse.detail);
        setIsLoading(false);
        return;
      }
    } catch {
      Alert.alert('Erro ao criar campanha :/');
    } finally {
      setIsLoading(false);
      navigation.navigate('Search');
    }
  }, [data, navigation]);

  const renderRow = (item: Step) => {
    const elements = {
      'Dados básicos': (
        <>
          <FieldTitle>Nome da campanha</FieldTitle>
          <FieldText>{data.title}</FieldText>
          <FieldTitle>Descrição</FieldTitle>
          <FieldText>{data.description}</FieldText>
        </>
      ),
      Público: (
        <>
          <FieldTitle>Distancia</FieldTitle>
          <FieldText>
            {data.range.toPrecision(2)}
            km
          </FieldText>
          <FieldTitle>Sexos</FieldTitle>
          <FieldText>
            {handleArray(data.sex)}
          </FieldText>
        </>
      ),
      Orçamento: (
        <>
          <FieldTitle>Valor</FieldTitle>
          <FieldText>
            {(data.value || 0).toLocaleString('pt-BR', {
              currency: 'BRL',
              style: 'currency',
            })}
          </FieldText>
        </>
      ),
    };

    return elements[item];
  };

  return (
    <>
      <Content>
        {
      blocks.map((item) => (

        <BoxContainer
          key={item}
          style={{
            elevation: 5,
            shadowColor: 'black',
            shadowOffset: {
              height: 0,
              width: 0,
            },
            shadowOpacity: 0.15,
            shadowRadius: 10,
          }}
        >
          <TitleContainer>
            {renderIcon(item)}
            <Title>{item}</Title>
          </TitleContainer>
          {renderRow(item)}
        </BoxContainer>
      ))
      }
      </Content>
      <ButtonContainer>
        <Button title="Confirmar anúncio" onPress={handleFinish} isLoading={isLoading} isInactive={isLoading} />
      </ButtonContainer>
    </>

  );
};
