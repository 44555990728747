import { RFValue } from 'react-native-responsive-fontsize';
import styled from 'styled-components/native';

export const Container = styled.Pressable`
  flex: 1;
  padding: 0 20px;
  background: ${({ theme }) => theme.colors.background};
`;

export const Header = styled.View`
  padding: 32px 0 24px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border-bottom-width: 0.5px;
  border-bottom-color: ${({ theme }) => theme.colors.subtitle};
`;

export const Box = styled.View`
  width: 48px;
  height: 48px;
`;

export const Title = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primarySemiBold};
  font-size: ${RFValue(18)}px;
  color: ${({ theme }) => theme.colors.subtitle};
`;

export const Main = styled.View`
  margin: 24px 0 40px;
  align-items: center;
`;

export const Text = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primaryMedium};
  font-size: ${RFValue(14)}px;
  color: ${({ theme }) => theme.colors.subtitle};
  margin: 14px 0 4px;
`;

export const CampaignTitle = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primarySemiBold};
  font-size: ${RFValue(16)}px;
  color: ${({ theme }) => theme.colors.subtitle};
  margin-bottom: 24px;
`;

export const AdTypeText = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primarySemiBold};
  font-size: ${RFValue(16)}px;
  color: ${({ theme }) => theme.colors.title};
`;

export const ButtonContainer = styled.View`
  margin-top: 32px;
`;
