import styled, { css } from 'styled-components/native';
import { RFValue } from 'react-native-responsive-fontsize';

interface Props {
  isActive: boolean;
}

export const Container = styled.TouchableOpacity<Props>`
  align-items: center;
  background: ${({ theme }) => theme.colors.gray};
  border-radius: 8px;
  ${({ isActive, theme }) => isActive && css`
    background:  ${theme.colors.green300};
  `}
`;

export const Title = styled.Text<Props>`
  font-family: ${({ theme }) => theme.fonts.primaryRegular};
  font-size: ${RFValue(14)}px;
  color: ${({ theme }) => theme.colors.title};
  margin: 10px;

  ${({ isActive, theme }) => isActive && css`
    color:  ${theme.colors.green600};
    font-family: ${theme.fonts.primarySemiBold};
  `}
`;
