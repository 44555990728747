import { RFValue } from 'react-native-responsive-fontsize';
import styled from 'styled-components/native';
import { AntDesign } from '@expo/vector-icons';

interface Props {
  hasArrowIcon?: boolean;
  leftIcon?: boolean;
}

export const Container = styled.TouchableOpacity`
  padding: 17px 24px;
  flex-direction: row;
  background: ${({ theme }) => theme.colors.secondaryBackground};
  border-radius: 8px;
  align-items: center;
`;

export const TextContainer = styled.View<Props>`
  flex: 1;
  margin-left: ${({ leftIcon }) => (leftIcon ? 17 : 0)}px;
`;

export const Title = styled.Text<Props>`
  font-family: ${({ theme }) => theme.fonts.primarySemiBold};
  color: ${({ theme }) => theme.colors.title};
  font-size: ${({ hasArrowIcon }) => (hasArrowIcon ? RFValue(16) : RFValue(14))}px;
`;

export const SubTitle = styled.Text<Props>`
  font-family: ${({ theme }) => theme.fonts.primaryRegular};
  color: ${({ theme, hasArrowIcon }) => (hasArrowIcon ? theme.colors.subtitle : theme.colors.title)};
  font-size: ${RFValue(12)}px;
`;

export const Icon = styled(AntDesign).attrs({
  name: "arrowright",
})`
  font-size: 24px;
  color: ${({ theme }) => theme.colors.subtitle};
`;
