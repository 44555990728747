import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { Alert } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useMediaLibraryPermissions, launchImageLibraryAsync } from 'expo-image-picker';
import { AxiosError } from 'axios';
import { useAuth } from '../../hooks/auth';
import CameraIcon from '../../assets/svg/cameraIcon.svg';
import ProfileCategory from '../../assets/svg/profileCategory.svg';
import ProfileClock from '../../assets/svg/profileClock.svg';
import ProfilePhone from '../../assets/svg/profilePhone.svg';
import ProfileMap from '../../assets/svg/profileMap.svg';
import ProfileSite from '../../assets/svg/profileSite.svg';
import ProfileMenu from '../../assets/svg/profileMenu.svg';

import {
  ButtonContainer,
  Container,
  ContainerImage,
  Icon,
  Image,
  ImageText,
  ImageTextSub,
  InfoContainer,
  InfoText,
  RestaurantSubTitle,
  RestaurantSubTitleButton,
  RestaurantTitle,
  SubContainer,
} from './styles';
import { apiServices } from '../../services/apiServices';
import { fileService, FileTypeEnum } from '../../services/fileService';
import { Button } from '../../components/Button';

type ItensProps = 'menu' | 'address' | 'categories' | 'hour' | 'phone' | 'site';

export interface EditProfileProps {
  type: ItensProps | 'description';
}

export const Profile = () => {
  const hasMounted = useRef(false);
  const {
    updateUser, user, updateCompany, company, SignOut,
  } = useAuth();
  const navigation = useNavigation();
  const [imageUri, setImageUri] = useState('');
  const [statusMedia, requestMedia] = useMediaLibraryPermissions();
  const itens: ItensProps[] = ['menu', 'address', 'categories', 'hour', 'phone', 'site'];

  const handleImage = useCallback(async () => {
    if (!statusMedia?.granted) {
      requestMedia();
    }
    const media = await launchImageLibraryAsync();
    if (!media.cancelled) {
      const form = new FormData();
      form.append('photo', JSON.parse(JSON.stringify({
        uri: media.uri,
        name: `${media.type}${media.uri.slice(-4)}`,
        type: `${media.type}/${media.uri.slice(-3)}`,
      })));
      try {
        const result = await fileService.sendFile(media, FileTypeEnum.CompanyProfile);
        if (!result.success) throw Error(result.detail);

        const updateCompanyResult = await apiServices
          .updateCurrentCompany({ ...company, photoId: result.data.fileId, photo: null });

        if (!updateCompanyResult.success) {
          throw Error(updateCompanyResult.detail);
        }

        updateCompany(updateCompanyResult.data);

        setImageUri(result.data.url);
      } catch (err) {
        const e = err as AxiosError;
        Alert.alert('Erro', e.message);
      }
    }
  }, [statusMedia, requestMedia, company, updateCompany]);

  const handleEdit = (item: ItensProps | 'description') => {
    navigation.navigate('EditProfile', {
      type: item,
    });
  };

  const handleIcon = (item: ItensProps) => {
    const data = {
      address: <ProfileMap />,
      categories: <ProfileCategory />,
      hour: <ProfileClock />,
      phone: <ProfilePhone />,
      site: <ProfileSite />,
      menu: <ProfileMenu />,
    };
    return data[item];
  };

  const handleInfoText = (item: ItensProps) => {
    const data = {
      address: 'Defina um endereço',
      categories: 'Suas categorias',
      hour: 'Adicionar horário de funcionamento',
      phone: 'Adicionar telefone',
      site: 'Adicionar site',
      menu: 'Adicionar cardápio',
    };
    return data[item];
  };

  const onLoad = useCallback(async () => {
    const result = await apiServices.getCurrentCompany();
    if (!result.success) return;
    const { data } = result;

    updateCompany(result.data);
    if (result?.data?.photo) setImageUri(result.data.photo.url);

    if (!data?.masterLocation) return;
    updateUser({
      ...user,
      about: data.about,
      website: data.website,
    });
  }, [user, updateUser, updateCompany]);

  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;
      onLoad();
    }
  }, [onLoad]);

  return (
    <Container>
      <ContainerImage onPress={handleImage}>
        {imageUri
          ? (<Image source={{ uri: imageUri }} resizeMode="cover" />)
          : (
            <>
              <CameraIcon />
              <ImageText>Adicionar foto da capa</ImageText>
              <ImageTextSub>Ela aparece em todo lugar</ImageTextSub>
            </>
          )}
      </ContainerImage>
      <SubContainer>
        <RestaurantTitle>{company.name || user.name}</RestaurantTitle>
        <RestaurantSubTitle onPress={() => handleEdit('description')}>
          {company.about || 'Digite aqui sua descrição.'}
          <RestaurantSubTitleButton> Editar</RestaurantSubTitleButton>
        </RestaurantSubTitle>
      </SubContainer>
      {itens.map((item, index) => (
        <InfoContainer key={index.toString()} onPress={() => handleEdit(item)}>
          {handleIcon(item)}
          <InfoText>
            {handleInfoText(item)}
          </InfoText>
          <Icon />
        </InfoContainer>
      ))}
      <ButtonContainer>
        <Button title="Sair" isOutline onPress={SignOut} />
      </ButtonContainer>
    </Container>
  );
};
