import React, { useEffect } from 'react';
import { useNavigation } from '@react-navigation/native';
import Animated, {
  runOnJS, useAnimatedStyle, useSharedValue, withTiming,
} from 'react-native-reanimated';
import { useFirstEntry } from '../../hooks/firstEntry';

import Logo from '../../assets/svg/Logo.svg';

import { Container } from './styles';
import { useAuth } from '../../hooks/auth';

export const Splash = () => {
  const ImageOpacity = useSharedValue(0.1);
  const navigation = useNavigation();
  const { setShowedSplash } = useFirstEntry();
  const { user } = useAuth();

  const firstScreen = () => {
    setShowedSplash();
    if (user.userId) {
      navigation.navigate('Main');
    } else {
      navigation.navigate('Welcome');
    }
  };

  useEffect(() => {
    ImageOpacity.value = withTiming(1, {
      duration: 2000,
    }, (value) => {
      'worklet';

      if (value) runOnJS(firstScreen)();
    });
  });

  const imageStyle = useAnimatedStyle(() => ({ opacity: ImageOpacity.value }));
  return (
    <Container>
      <Animated.View style={imageStyle}>
        <Logo />
      </Animated.View>
    </Container>
  );
};
