import styled from 'styled-components/native';

interface Props {
  active: boolean;
}

export const Container = styled.View`
  flex-direction: row;
  align-self: center;
`;

export const PaginationContainer = styled.View<Props>`
  width: 48px;
  height: 4px;
  border-radius: 8px;
  background: ${({ theme, active }) => (active ? theme.colors.primary : theme.colors.gray)};
  margin: 0 6px 0 6px;
`;
