import { getBottomSpace } from "react-native-iphone-x-helper";
import { TextInputMask } from "react-native-masked-text";
import { RFValue } from "react-native-responsive-fontsize";
import styled, { css } from "styled-components/native";

interface Props {
  isActive: boolean;
}

export const Title = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primarySemiBold};
  font-size: ${RFValue(16)}px;
  color: ${({ theme }) => theme.colors.title};
  margin-bottom: 24px;
`;

export const ModalContainer = styled.Pressable`
  flex: 1;
  background: rgba(0, 0, 0, 0.2);
  justify-content: flex-end;
`;

export const DateContainer = styled.View`
  background: ${({ theme }) => theme.colors.background};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 43px 32px ${getBottomSpace() + 20}px;
  height: 80%;
`;

export const DateModalText = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primaryMedium};
  font-size: ${RFValue(16)}px;
  color: ${({ theme }) => theme.colors.subtitle};
  margin: 24px 0;
`;

export const DateInputContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
`;

export const DateInput = styled(TextInputMask)<Props>`
  width: 92px;
  height: 35px;
  text-align: center;
  border-radius: 8px; 
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.gray100};
  font-family: ${({ theme }) => theme.fonts.primaryRegular};
  font-size: ${RFValue(14)}px;


  ${({ isActive, theme }) => isActive && css`
    background: ${theme.colors.primary};
    color: ${theme.colors.background};
  `}
`;

export const ButtonContainerModal = styled.View`
  flex-direction: row;
  align-items: center;
  padding: 30px;
  width: 80%;
`;

export const CleanButton = styled.Text.attrs({
  children: 'Limpar',
})`
  font-family: ${({ theme }) => theme.fonts.primaryMedium};
  font-size: ${RFValue(16)}px;
  color: ${({ theme }) => theme.colors.subtitle};
  margin-right: 28px;
`;

export const ValueContainer = styled.View`
  margin-top: 24px;
`;

export const ButtonDate = styled.TouchableOpacity`
  flex: 1;
  height: 100%;
  

  ${({ hasLeftIcon }) => hasLeftIcon && css`
    margin-left: 16px;
  `}

  width: 100%;
  height: 60px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.secondaryBackground};
  padding: 0 20px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonDateText = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primaryMedium};
  color: ${({ theme }) => theme.colors.title};
  font-size: ${RFValue(14)}px;

`;
