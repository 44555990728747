import React from 'react';

import Slider from '@react-native-community/slider';

import { useTheme } from 'styled-components/native';
import { Button } from '../../../../components/Button';

import { ButtonContainer } from '../BasicData/styles';

import {
  TitleContainer, Title, Description, DescriptionContainer, DescriptionSex,
} from './styles';
import { CheckBox } from '../../../../components/CheckBox';

interface Props {
  range: number;
  setRange: (value: number) => void;
  handleSteps: () => void;
  sex: string[];
  setSex: (value: string[]) => void;
}

export const LocationData = ({
  handleSteps, range, setRange, sex, setSex,
}: Props) => {
  const theme = useTheme();
  const sexs = ['Masculino', 'Feminino', 'Outros'];

  const handleList = (item: string) => {
    const result = new Set(sex);
    if (result.has(item)) {
      result.delete(item);
    } else {
      result.add(item);
    }

    setSex(Array.from(result));

    // const result2 = sex;
    // if (sex.some((element) => element === item)) {
    //   result2.splice(result2.indexOf(item), 1);
    // } else {
    //   result2.push(item);
    // }
    // setSex(result2);
  };

  return (
    <>
      <TitleContainer>
        <Title>Distancia</Title>
        <Description>
          {Math.floor(range) || 0}
          {range === 15 && '+'}
          {' '}
          km
        </Description>
      </TitleContainer>
      <Slider
        minimumTrackTintColor={theme.colors.primary}
        value={range}
        onValueChange={(value) => setRange(Math.floor(value))}
        minimumValue={0}
        maximumValue={15}
      />
      <Title>Selecione o sexo</Title>
      {sexs.map((item) => (
        <DescriptionContainer key={item}>
          <CheckBox
            isActive={sex ? sex.some((element) => item === element) : false}
            onPress={() => handleList(item)}
          />
          <DescriptionSex>{item}</DescriptionSex>
        </DescriptionContainer>
      ))}
      <ButtonContainer>
        <Button title="Continuar" isInactive={!range} onPress={handleSteps} />
      </ButtonContainer>
    </>
  );
};
