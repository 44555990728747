import { MaterialIcons } from '@expo/vector-icons';
import { RFValue } from 'react-native-responsive-fontsize';
import styled from 'styled-components/native';

export const Container = styled.TouchableOpacity`
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ProfileView = styled.View`
  height: 56px;
  width: 56px;
  border-radius: 28px;
  background: ${({ theme }) => theme.colors.primary};
  align-items: center;
  justify-content: center;
`;

export const Block = styled.View`
  margin-left: 16px;
`;

export const ProfileText = styled.Text`
  color: ${({ theme }) => theme.colors.title};
  font-family: ${({ theme }) => theme.fonts.primaryMedium};
  font-size: ${RFValue(16)}px;
`;

export const Image = styled.Image`
  height: 100%;
  width: 100%;
  border-radius: 28px;
  position: absolute;
`;

export const OverlayPhoto = styled.View`
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

export const Title = styled.Text`
  color: ${({ theme }) => theme.colors.title};
  font-family: ${({ theme }) => theme.fonts.primarySemiBold};
  font-size: ${RFValue(14)}px;
`;
export const SubTitle = styled.Text`
  color: ${({ theme }) => theme.colors.subtitle};
  font-family: ${({ theme }) => theme.fonts.primaryRegular};
  font-size: ${RFValue(12)}px;
`;

export const Icon = styled(MaterialIcons).attrs({
  name: 'chevron-right',
})`
  color: ${({ theme }) => theme.colors.title};
  font-size: ${RFValue(24)}px;
`;

export const Group = styled.View`
  flex-direction: row;
  align-items: center;
`;
