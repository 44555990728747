import { useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import { BackButton } from '../../../components/BackButton';
import { Button } from '../../../components/Button';
import { AnimatedPlaceholderInput } from '../../../components/inputs/AnimatedPlaceholderInput';

import {
  ButtonContainer,
  Container, SubTitle, Title,
} from './styles';

export const FirstStep = () => {
  const [inputValue, setInputValue] = useState('');
  const navigation = useNavigation();

  const handleNextStep = () => {
    navigation.navigate('SecondStep', inputValue);
  };
  return (
    <Container>
      <BackButton />
      <Title>Recuperar senha</Title>
      <SubTitle>Insira o e-mail cadastrado</SubTitle>
      <AnimatedPlaceholderInput
        animatedValue={inputValue}
        placeholder="Insira seu e-mail"
        onChangeText={setInputValue}
        keyboardType="email-address"
        autoCapitalize="none"
      />
      <ButtonContainer>
        <Button
          title="Enviar"
          isInactive={!inputValue}
          onPress={handleNextStep}
        />
      </ButtonContainer>
    </Container>
  );
};
