import { AxiosError } from "axios";
import { ImageInfo } from "expo-image-picker/build/ImagePicker.types";
import { api } from "./axiosInstances";
import { secureCommandRequests } from "./utils/secureCommandRequests";

export enum FileTypeEnum {
  Banner = "banner",
  Menu = "menu",
  UserProfile = "user-profile",
  CompanyProfile = "company-profile",
}

export const fileService = {
  sendFile: async (file: ImageInfo, fileType: FileTypeEnum) => {
    const localUri = file.uri;
    const filename = localUri.split('/').pop() as string;

    // Infer the type of the image
    const match = /\.(\w+)$/.exec(filename);
    let type:string;
    if (fileType !== FileTypeEnum.Menu) {
      type = match ? `image/${match[1]}` : `image`;
    } else {
      type = "application/pdf";
    }
    const formData = new FormData();

    formData.append('File', JSON.parse(JSON.stringify({ uri: localUri, name: filename, type })));
    formData.append("TypeCode", fileType);

    const result = await secureCommandRequests(api.post<{url: string, fileId: string}>(`/files`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      },
    }));

    return result;
  },
};
