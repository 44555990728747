import { MaterialCommunityIcons } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import { getBottomSpace, getStatusBarHeight } from 'react-native-iphone-x-helper';
import { RFValue } from 'react-native-responsive-fontsize';
import styled from 'styled-components/native';

export const Container = styled.View`
  flex: 1;
  background: ${({ theme }) => theme.colors.background};
`;

export const HeaderContainer = styled.ImageBackground`
  height: 40%;
`;

export const Header = styled(LinearGradient).attrs({
  colors: ['rgba(0,10,3,1)', 'rgba(0,10,3,0.11)'],
})`
  padding: 0 20px 16px 22px;
  flex: 1;
  justify-content: space-between;
`;

export const ButtonContainer = styled(Header)`
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 16px 20px ${getBottomSpace()}px;
`;

export const HeaderButtonsContainer = styled.View`
  margin-top: ${getStatusBarHeight() + 33}px;
  flex-direction: row;
  justify-content: space-between;
`;

export const OptionsButtonContainer = styled.TouchableOpacity.attrs({
  activeOpacity: 0.6,
})`
  height: 48px;
  width: 48px;
  border-radius: 21px;
  background-color: ${({ theme }) => theme.colors.secondaryBackground};
  opacity: 0.7;
  align-items: center;
  justify-content: center;
`;

export const Icon = styled(MaterialCommunityIcons).attrs({
  name: 'dots-vertical',
})`
  font-size: 24px;
  color: ${({ theme }) => theme.colors.title};
`;

export const HeaderContent = styled.View`
  width: 100%;

`;

export const CategoryText = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primaryRegular};
  font-size: ${RFValue(14)}px;
  color: ${({ theme }) => theme.colors.title};
`;

export const RestaurantText = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primarySemiBold};
  font-size: ${RFValue(18)}px;
  color: ${({ theme }) => theme.colors.title};
`;

export const ActionRowContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;
`;

export const ActionContainer = styled.TouchableOpacity`
  height: 43px;
  width: 101px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background: ${({ theme }) => theme.colors.secondaryBackground};
`;

export const ActionTitle = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primarySemiBold};
  font-size: ${RFValue(14)}px;
  color: ${({ theme }) => theme.colors.title};
  margin-left: 7px;
`;
