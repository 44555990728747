import { RFValue } from 'react-native-responsive-fontsize';
import styled, { css } from 'styled-components/native';

interface Props {
  isFocused?: boolean;
  isErrored?: boolean;
}

interface TextProps {
  hasValue: boolean;
}

export const Container = styled.Pressable<Props>`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding: 14px 56px;
  border-radius: 6px;
  background: ${({ theme }) => theme.colors.secondaryBackground};
  border-width: 1px;

  ${({ theme, isErrored }) => isErrored && css`
    border-color: ${theme.colors.error};
  `};

  ${({ theme, isFocused }) => isFocused && css`
    border-color: ${theme.colors.primary};
  `};
`;

export const Box = styled.View<Props>`
  height: 27px;
  width: 87px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  
`;

export const VisibleText = styled.Text<TextProps>`
  font-family: ${({ theme }) => theme.fonts.primaryRegular};
  font-size: ${RFValue(20)}px;
  color: ${({ theme, hasValue }) => (hasValue ? theme.colors.title : theme.colors.subtitle)};
`;

export const Input = styled.TextInput`
  position: absolute;
  width: 0px;
  height: 0px;
`;
