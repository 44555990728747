import { getBottomSpace } from "react-native-iphone-x-helper";
import { RFValue } from "react-native-responsive-fontsize";
import styled from "styled-components/native";

export const ButtonContainer = styled.View`
  margin-bottom: ${getBottomSpace() + 20}px;
  margin-top: 32px;
`;

export const DescriptionContainer = styled.View`
  margin: 24px 0 0;
`;

export const Title = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primarySemiBold};
  font-size: ${RFValue(16)}px;
  color: ${({ theme }) => theme.colors.title};
  margin-bottom: 24px;
`;

export const ImagePicker = styled.TouchableOpacity`
  width: 100%;
  height: 209px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.green50};
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  border-width: 1px;
  border-style: dashed;
  border-color: ${({ theme }) => theme.colors.green300};
  overflow: hidden;
`;

export const ImagePickerTitle = styled.Text.attrs({
  children: 'Escolha sua imagem',
})`
  font-family: ${({ theme }) => theme.fonts.primarySemiBold};
  font-size: ${RFValue(16)}px;
  color: ${({ theme }) => theme.colors.subtitle};
  margin-top: 8px;
`;
