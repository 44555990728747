import styled from "styled-components/native";
import { RFValue } from "react-native-responsive-fontsize";

export const InlineButton = styled.TouchableOpacity``;

export const Text = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primaryRegular};
  font-size: ${RFValue(14)}px;
  color: ${({ theme }) => theme.colors.title};
`;

export const InlineButtonText = styled(Text)`
  font-family: ${({ theme }) => theme.fonts.primarySemiBold};
  color: ${({ theme }) => theme.colors.primary};
`;
