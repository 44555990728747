import { getBottomSpace } from 'react-native-iphone-x-helper';
import { RFValue } from 'react-native-responsive-fontsize';
import styled from 'styled-components/native';

export const BoxContainer = styled.Pressable`
  width: 100%;
  height: 271px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.background};
  margin-top: 18px;
  padding: 24px 20px;
`;

export const TitleContainer = styled.View`
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
`;

export const Title = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primarySemiBold};
  font-size: ${RFValue(16)}px;
  color: ${({ theme }) => theme.colors.title};
  width: 40%;
  margin-left: 16px;
`;

export const FieldTitle = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primaryRegular};
  font-size: ${RFValue(12)}px;
  color: ${({ theme }) => theme.colors.subtitle};
`;

export const FieldText = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primaryMedium};
  font-size: ${RFValue(14)}px;
  color: ${({ theme }) => theme.colors.title};
  margin-bottom: 24px;
`;

export const ButtonContainer = styled.View`
  margin-bottom: ${getBottomSpace() + 32}px;
  position: absolute;
  bottom: -${getBottomSpace()}px;
  width: 100%;
  padding: 0 20px;
`;

export const Content = styled.ScrollView`
  padding: 0 20px;
`;
