import React from 'react';
import { BackButton } from '../BackButton';

import { Header, HeaderTitle, Right } from './styles';

interface Props {
  title: string;
}

export const GenericHeader = ({ title }: Props) => (
  <Header>
    <BackButton />
    <HeaderTitle>{title}</HeaderTitle>
    <Right />
  </Header>
);
