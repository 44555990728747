import styled from "styled-components/native";
import { getStatusBarHeight } from "react-native-iphone-x-helper";
import { RFValue } from "react-native-responsive-fontsize";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

export const Container = styled(KeyboardAwareScrollView).attrs({
  bounces: false,
})`
  flex: 1;
  background: ${({ theme }) => theme.colors.background};
  padding: ${getStatusBarHeight() + 24}px 20px;
`;

export const Title = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primaryBold};
  font-size: ${RFValue(24)}px;
  color: ${({ theme }) => theme.colors.title};
  margin-top: 32px;
`;

export const InputContainer = styled.View`
  margin: 32px 0 0;
`;

export const ButtonContainer = styled.View`
  margin-top: 32px;
`;

export const InlineButtonContainer = styled.View`
  align-self: center;
  margin-top: 24px;
`;

export const HelpContainer = styled.TouchableOpacity`
  flex-direction: row;
  align-items: flex-end;
`;

export const HelpText = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primaryRegular};
  font-size: ${RFValue(13)}px;
  color: ${({ theme }) => theme.colors.title};
  margin-right: 8px;
`;

export const EyeContainer = styled.TouchableOpacity`
  height: 100%;
  justify-content: center;
  align-items: flex-end;
  width: 60px;
`;

export const Header = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderEnd = styled.View`
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;
