import React, { useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useForm, useWatch } from 'react-hook-form';

import { AxiosError } from 'axios';
import { BackButton } from '../../../components/BackButton';
import { AnimatedPlaceholderInputForm } from '../../../components/inputs/AnimatedPlaceholderInputForm';
import { Button } from '../../../components/Button';
import { AnimatedPlaceholderMaskedInputForm } from '../../../components/inputs/AnimatedPlaceholderMaskedInputForm';
import { Pagination } from '../../../components/Pagination';

import {
  ButtonContainer,
  Container,
  InputContainer,
  ProgressContainer,
  TermsButtonText,
  TermsText,
  Title,
} from './styles';
import { api } from '../../../services/axiosInstances';

export interface FirstFormData {
  name: string;
  cnpj: string;
}

export const FirstForm = () => {
  const navigation = useNavigation();
  const { control, handleSubmit } = useForm();
  const formWatch = useWatch({ control });
  const [cnpjValidator, setCnpjValidator] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleFirstForm = async (form: FirstFormData) => {
    const data: FirstFormData = {
      name: form.name,
      cnpj: form.cnpj,
    };
    setIsLoading(true);
    try {
      await api.post('/companies/validate/cnpj', { cnpj: data.cnpj });
      navigation.navigate('SecondForm', data);
      setCnpjValidator('');
    } catch (error) {
      const err = error as AxiosError;

      setCnpjValidator(
        Object.values(
          err.response?.data.errors,
        )[0][0] || '',
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleTerms = () => {
    //
  };

  const handlePolicy = () => {
    //
  };

  return (
    <Container>
      <ProgressContainer>
        <Pagination hasFirstActive />
      </ProgressContainer>
      <BackButton />
      <Title>Sobre sua empresa</Title>
      <InputContainer>
        <AnimatedPlaceholderInputForm
          placeholder="Nome do estabelecimento"
          name="name"
          control={control}
          autoCapitalize="words"
          autoCorrect={false}
        />
      </InputContainer>
      <AnimatedPlaceholderMaskedInputForm
        placeholder="CNPJ"
        name="cnpj"
        control={control}
        keyboardType="numeric"
        type="cnpj"
        error={cnpjValidator}
      />
      <ButtonContainer>
        <Button
          title="Continuar"
          onPress={handleSubmit(handleFirstForm)}
          isInactive={
            !formWatch.name
            || (formWatch?.cnpj?.length < 18 || isLoading)
          }
          isLoading={isLoading}
        />
      </ButtonContainer>
      <TermsText>
        Ao ”tocar” no botão continuar, você aceita
        {'\n'}
        nosso
        {' '}
        <TermsButtonText onPress={handleTerms}>
          termos de uso
        </TermsButtonText>
        {' '}
        e
        {' '}
        <TermsButtonText onPress={handlePolicy}>
          política de privacidade
        </TermsButtonText>
      </TermsText>
    </Container>

  );
};
