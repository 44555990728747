import React from 'react';
import { CardStyleInterpolators, createStackNavigator } from '@react-navigation/stack';

import { Splash } from '../screens/Splash';
import { Logged } from './Logged.routes';
import { useFirstEntry } from '../hooks/firstEntry';
import { Restaurant } from '../screens/Restaurant';
import { GenericAd } from '../screens/GenericAd';
import { CampaignAd } from '../screens/CampaignAd';
import { EditProfile } from '../screens/EditProfile';

const { Navigator, Screen, Group } = createStackNavigator();

export const MainNavigation = () => {
  const { hasShowedSplash } = useFirstEntry();
  return (
    <Navigator
      screenOptions={{
        headerShown: false,
        cardStyleInterpolator: CardStyleInterpolators.forFadeFromCenter,
        gestureEnabled: false,
        detachPreviousScreen: false,
      }}
    >
      {
        !hasShowedSplash
        && <Screen name="Splash" component={Splash} />
      }
      <Screen name="Main" component={Logged} />

      <Group
        screenOptions={{
          cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          gestureEnabled: true,
        }}
      >
        <Screen name="Restaurant" component={Restaurant} />

      </Group>
      <Group screenOptions={{
        cardStyleInterpolator: CardStyleInterpolators.forModalPresentationIOS,
        gestureEnabled: true,
        gestureDirection: 'vertical',
      }}
      >
        <Screen
          name="GenericAd"
          component={GenericAd}
        />
        <Screen
          name="CampaignAd"
          component={CampaignAd}
        />
        <Screen
          name="EditProfile"
          component={EditProfile}
        />
      </Group>

    </Navigator>
  );
};
