import { RFValue } from 'react-native-responsive-fontsize';
import styled from 'styled-components/native';

export const Container = styled.View`
  flex: 1;
  background: ${({ theme }) => theme.colors.background};
`;

export const ButtonHeaderContainer = styled.TouchableOpacity`
  background: rgba(255, 255, 255, 0.24);
  width: 40px;
  height: 40px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
`;

export const ModalizeTitle = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primarySemiBold};
  font-size: ${RFValue(20)}px;
  margin-bottom: 16px;
`;

export const ModalizeSubTitle = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primaryRegular};
  font-size: ${RFValue(14)}px;
  margin-bottom: 32px;
  color: ${({ theme }) => theme.colors.subtitle};
`;

export const ButtonTextContainer = styled.Pressable`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 11px 14px;
  margin-bottom: 14px;
  border-radius: 10px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.gray100};
`;

export const ButtonText = styled.View`
  flex: 1;
  margin-left: 14px;
`;
export const ButtonTextTitle = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primaryRegular};
  color: ${({ theme }) => theme.colors.subtitle};
  font-size: ${RFValue(12)}px;
  line-height: 18px;
`;

export const ButtonTextCTA = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primaryMedium};
  color: ${({ theme }) => theme.colors.title};
  font-size: ${RFValue(14)}px;

`;

export const Empty = styled.Text.attrs({
  children: 'Criar anúncio',
})`
  font-family: ${({ theme }) => theme.fonts.primaryMedium};
  color: ${({ theme }) => theme.colors.background};
  font-size: ${RFValue(14)}px;
  text-align: center;
`;

export const Separator = styled.View`
  height: 24px;
`;

export const EmptyButton = styled.TouchableOpacity`
  background: ${({ theme }) => theme.colors.primary};
  padding: 14px;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  border-radius: 12px;
`;
