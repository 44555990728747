import React, {
  useCallback, useMemo, useState,
} from 'react';
import { useRoute } from '@react-navigation/native';
import { Keyboard } from 'react-native';
import { useSharedValue } from 'react-native-reanimated';

import { ImageInfo } from 'expo-image-picker/build/ImagePicker.types';
import { BackButton } from '../../components/BackButton';
import { ProgressBar } from './components/ProgressBar';
import BasicData from './components/BasicData';
import { LocationData } from './components/LocationData';
import { DateData, DateRangeArgs } from './components/DateData';
import { Review } from './components/Review';

import {
  Box,
  Container,
  Content,
  Header,
  ProgressContainer,
  Title,
} from './styles';

export interface Ivalues {
  name: string;
  campaignTypeCode: string;
  title: string;
  description: string;
  sex: string[];
  dateRange: DateRangeArgs;
  range: number;
  value: number;
  image?: ImageInfo;
  initialHour: string;
  setInitialHour: (value: string) => void;
  endHour: string;
  setEndHour: (value: string) => void;
}

export interface GenericAdProps {
  campaignName: string;
  adType: 'banner' | 'push-notification';
}

export const CampaignAd = () => {
  const { params } = useRoute();
  const { campaignName, adType } = params as GenericAdProps;
  const progressData = useMemo(() => ['Dados básicos', 'Público', 'Orçamento'], []);
  const progress = useSharedValue(1 / progressData.length);
  const [currentStep, setCurrentStep] = useState(0);

  const [values, setValues] = useState({} as Ivalues);

  const handleSteps = useCallback(() => {
    if (currentStep !== 3) {
      if (currentStep < 2) progress.value += 1 / progressData.length;
      setCurrentStep((oldState) => oldState + 1);
    }
  }, [currentStep, progress, progressData]);

  const handleNavigation = useCallback((value: number) => {
    if (currentStep >= value) {
      setCurrentStep(value);
      progress.value = ((1 / progressData.length) * (value + 1));
    }
  }, [progress, progressData, currentStep]);

  const renderStep = useCallback(() => {
    const data = [
      <BasicData
        handleSteps={handleSteps}
        adType={adType}
        titleValue={values.title}
        setTitleValue={(title: string) => setValues((oldState) => ({ ...oldState, title }))}
        setDescriptionValue={(description: string) => {
          setValues((oldState) => ({ ...oldState, description }));
        }}
        descriptionValue={values.description}
        image={values.image}
        setImage={(image) => setValues((oldState) => ({ ...oldState, image }))}
      />,
      <LocationData
        handleSteps={handleSteps}
        range={values.range}
        setRange={(range: number) => setValues((oldState) => ({ ...oldState, range }))}
        sex={values.sex}
        setSex={(sex: string[]) => setValues((oldState) => ({ ...oldState, sex }))}
      />,
      <DateData
        handleSteps={handleSteps}
        setDateRange={(dateRange) => setValues((oldState) => ({ ...oldState, dateRange }))}
        value={values.value}
        setValue={(value) => setValues((oldState) => ({ ...oldState, value }))}
        initialHour={values.initialHour}
        setInitialHour={(initialHour) => setValues((oldState) => ({ ...oldState, initialHour }))}
        setEndHour={(endHour) => setValues((oldState) => ({ ...oldState, endHour }))}
        endHour={values.endHour}
      />,
      <Review
        data={{ ...values, name: campaignName, campaignTypeCode: adType }}
        blocks={progressData}
      />,
    ];
    return data[currentStep];
  }, [handleSteps, adType, values, campaignName, progressData, currentStep]);

  return (
    <Container onPress={Keyboard.dismiss}>
      <Header>
        <BackButton
          inverted
          onPress={currentStep === 3 ? () => setCurrentStep((oldState) => oldState - 1) : undefined}
        />
        <Title>
          {currentStep < 3 ? 'Criar anúncio' : 'Revisar anúncio'}
        </Title>
        <Box />
      </Header>
      {currentStep < 3 ? (
        <Content>
          <ProgressContainer>
            <ProgressBar
              navigation={handleNavigation}
              progress={progress.value}
              steps={progressData}
            />
          </ProgressContainer>
          {renderStep()}
        </Content>
      )
        : renderStep()}
    </Container>
  );
};
