import { useNavigation, useRoute } from '@react-navigation/native';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  Alert,
  FlatList, Keyboard,
} from 'react-native';
import { AxiosError } from 'axios';
import { useAuth } from '../../../hooks/auth';
import { useBlockBackButton } from '../../../hooks/blockBackButton';

import { Button } from '../../../components/Button';
import { FoodItem } from '../../../components/FoodItem';
import { BaseInput } from '../../../components/inputs/BaseInput';

import {
  ButtonContainer,
  Container,
  HeaderContainer,
  HeaderText,
  SearchIcon,
  SectionContainer,
} from './styles';
import { api } from '../../../services/axiosInstances';
import { FirstFormData } from '../FirstForm';

export interface foodItemProps {
  name: string;
}

interface categoriesProps {
  companyCategoryId: string,
  name: string,
}

export interface SecondFormData extends FirstFormData {
  email: string;
  password: string;
  phone: string;
  promotional_emails: boolean;
}

export const LastForm = () => {
  const firstRender = useRef(true);
  const { params } = useRoute();
  const navigation = useNavigation();
  const { SignIn } = useAuth();
  const secondFormData = params as SecondFormData;

  const [categories, setCategories] = useState<categoriesProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [selectedFoods, setSelectedFoods] = useState('');
  const [filteredCategories, setFilteredCategories] = useState<categoriesProps[]>([]);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const handleFoodSelect = (item: string) => {
    if (selectedFoods !== item) {
      setSelectedFoods(item);
    } else {
      setSelectedFoods('');
    }
  };

  const handleSignUp = async () => {
    setIsLoading(true);
    const data = { ...secondFormData, companyCategoryId: selectedFoods };
    try {
      setIsLoading(true);
      await api.post('/auth/sign-up/cnpj', data);
      await SignIn({
        email: secondFormData.email,
        password: secondFormData.password,
        firstAccess: true,
      });
    } catch (err) {
      const error = err as AxiosError;
      Alert.alert('Não foi possivel realizar essa operação', Object.values(error.response?.data.errors)[0][0]);
      setIsLoading(false);
      navigation.goBack();
    }
  };

  const handleFilter = () => {
    if (!inputValue) {
      return categories;
    }
    return filteredCategories;
  };

  const getNextPageAsync = useCallback(async (number: number) => {
    if (number > maxPage) return;
    const { data } = await api.get(`/company-categories?page=${number}`);
    setCategories((oldState) => oldState.concat(data.result));
    setMaxPage(data.totalPages);
    setPage((oldState) => oldState + 1);
  }, [maxPage]);

  useEffect(() => {
    if (!firstRender.current) return;
    firstRender.current = false;
    (async () => {
      try {
        await getNextPageAsync(page);
      } catch {
        Alert.alert('Estamos com problem em nosso servidor', 'tente novamente mais tarde');
        navigation.goBack();
      }
    })();
  }, [getNextPageAsync, navigation, page]);

  useEffect(() => {
    (async () => {
      const { data } = await api.get(`/company-categories?Name=${inputValue}`);
      setFilteredCategories(data.result);
    })();
  }, [inputValue]);

  useBlockBackButton();

  return (
    <Container onPress={Keyboard.dismiss}>
      <HeaderContainer>
        <HeaderText>
          Selecione as categorias
          {'\n'}
          do seu restaurante
        </HeaderText>
        <BaseInput
          onChangeText={setInputValue}
          placeholder="Procurar categoria"
          leftIcon={<SearchIcon />}
          value={inputValue}
        />
      </HeaderContainer>
      <FlatList
        showsVerticalScrollIndicator={false}
        scrollEventThrottle={16}
        data={handleFilter()}
        keyExtractor={(item) => item.companyCategoryId}
        contentContainerStyle={{
          paddingVertical: 32,
          paddingBottom: 100,
        }}
        renderItem={({ item }) => (
          <SectionContainer>
            <FoodItem
              name={item.name}
              isActive={selectedFoods === item.companyCategoryId}
              onPress={() => handleFoodSelect(item.companyCategoryId)}
            />
          </SectionContainer>
        )}
        onEndReached={async () => { await getNextPageAsync(page); }}
        onEndReachedThreshold={0.2}
      />
      <ButtonContainer>
        <Button
          title={selectedFoods ? "Finalizar" : "Seleciona sua categoria"}
          isInactive={!selectedFoods || isLoading}
          isLoading={isLoading}
          onPress={handleSignUp}
        />
      </ButtonContainer>
    </Container>
  );
};
