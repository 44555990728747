import React from 'react';
import { TextStyle, TouchableOpacityProps } from 'react-native';

import { InlineButton, InlineButtonText, Text } from './styles';

interface Props extends TouchableOpacityProps {
  message: string;
  buttonLabel: string;
  textStyle?: TextStyle;
}

export const InlineButtonMessage = ({
  message,
  buttonLabel,
  textStyle,
  ...rest
}: Props) => (
  <InlineButton {...rest}>
    <Text style={textStyle}>
      {message}
      {' '}
      <InlineButtonText style={textStyle}>
        {buttonLabel}
      </InlineButtonText>
    </Text>
  </InlineButton>
);
