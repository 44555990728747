import React from 'react';
import { GenericHeader } from '../../components/GenericHeader';
import { useAuth } from '../../hooks/auth';
import { Button } from './components/Button';

import {
  ButtonContainer,
  Container,
} from './styles';

export const Settings = () => {
  const { SignOut } = useAuth();
  return (
    <Container>
      <GenericHeader title="Configurações" />
      <ButtonContainer>
        <Button title="Politicas de privacidade" />
      </ButtonContainer>
      <ButtonContainer>
        <Button title="Termos de uso" />
      </ButtonContainer>
      <ButtonContainer>
        <Button title="Limpar histórico de busca" />
      </ButtonContainer>
      <Button title="Sair" disableArrow onPress={() => SignOut()} />
    </Container>
  );
};
