import styled from 'styled-components/native';
import { getStatusBarHeight } from 'react-native-iphone-x-helper';
import { RFValue } from 'react-native-responsive-fontsize';
import { Modalize } from 'react-native-modalize';

export const Container = styled.Pressable`
  flex: 1;
  background: ${({ theme }) => theme.colors.background};
  padding: ${getStatusBarHeight() + 24}px 20px 0px;
`;

export const HeaderContainer = styled.View``;

export const Title = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primaryRegular};
  font-size: ${RFValue(14)}px;
  color: ${({ theme }) => theme.colors.title};
  text-align: center;
`;

export const SubTitle = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primaryBold};
  font-size: ${RFValue(20)}px;
  color: ${({ theme }) => theme.colors.title};
  text-align: center;
  margin-bottom: 32px;
`;

export const TipContainer = styled.View`
  margin-top: 24px;
`;

export const TipText = styled(Title)`
  font-size: ${RFValue(12)}px;
`;

export const TipTextSemi = styled(TipText)`
  font-family: ${({ theme }) => theme.fonts.primarySemiBold};
  color: ${({ theme }) => theme.colors.primary};

`;

export const Separator = styled.View`
  height: 24px;
`;

export const Modal = styled(Modalize).attrs(({ theme }) => ({
  handleStyle: {
    backgroundColor: theme.colors.background,
  },
  modalStyle: {
    backgroundColor: theme.colors.background,
  },
  adjustToContentHeight: true,
}))``;

export const VisibleModalContainer = styled.View`
  background: ${({ theme }) => theme.colors.background};
  height: 310px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 40px 0 48px; 
  align-items: center;
`;

export const ModalTitle = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primarySemiBold};
  font-size: ${RFValue(20)}px;
  color: ${({ theme }) => theme.colors.title};
  margin-bottom: 16px;
`;

export const ModalSubTitle = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primaryRegular};
  font-size: ${RFValue(14)}px;
  color: ${({ theme }) => theme.colors.subtitle};
  margin-bottom: 40px;
  text-align: center;
`;

export const ActionButtonContainer = styled.View`
  flex-direction: row;
`;

export const ActionButton = styled.TouchableOpacity`
  height: 98px;
  width: 131px;
  border-radius: 7px;
  align-items: center;
  justify-content: space-evenly;
  background: ${({ theme }) => theme.colors.primary};
  margin-right: 8px;
  margin-left: 8px;
`;

export const ActionText = styled(ModalSubTitle)`
  margin: 0;
  color: ${({ theme }) => theme.colors.background};
`;
