import { getStatusBarHeight } from 'react-native-iphone-x-helper';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { RFValue } from 'react-native-responsive-fontsize';
import styled from 'styled-components/native';

export const Container = styled.Pressable`
  flex: 1;
  background: ${({ theme }) => theme.colors.background};
`;

export const Header = styled.View`
  padding: ${getStatusBarHeight() + 32}px 10px 24px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.colors.backgroundTitle};
`;

export const Content = styled(KeyboardAwareScrollView)`
  padding: 26px 20px;
`;

export const Box = styled.View`
  width: 48px;
  height: 48px;
`;

export const ProgressContainer = styled.View`
  margin-bottom: 32px;
`;

export const Title = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primarySemiBold};
  font-size: ${RFValue(18)}px;
  color: ${({ theme }) => theme.colors.background};
`;
