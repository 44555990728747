import moment from 'moment';
import React from 'react';

import {
  Box, Container, DateText, TextContainer, Title,
} from './styles';

interface Props {
  title: string;
  type: 'banner' | 'push-notification';
  value: number;
  hour: Date;
}

export const Acitivity = ({
  title,
  hour,
  type,
  value,
}: Props) => (
  <Container>
    <Box type={type} />
    <TextContainer>
      <Title>{title}</Title>
      <DateText>
        {moment(hour).format('HH:mm')}
      </DateText>
    </TextContainer>
    <Title>
      {value.toLocaleString('pr-BR', {
        currency: 'BRL',
        style: 'currency',
      })}
    </Title>
  </Container>
);
