import styled, { css } from "styled-components/native";
import { RFValue } from "react-native-responsive-fontsize";

interface Props {
  isFocused: boolean;
}

interface InputProps {
  hasLeftIcon: boolean;
}

export const Container = styled.View<Props>`
  width: 100%;
  height: 60px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.secondaryBackground};
  padding: 0 20px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-color: transparent;

  border-width: 1px;
  ${({ isFocused, theme }) => isFocused && css`
    border-color: ${theme.colors.primary};
  `}
`;

export const Input = styled.TextInput<InputProps>`
  flex: 1;
  height: 100%;
  font-family: ${({ theme }) => theme.fonts.primaryMedium};
  color: ${({ theme }) => theme.colors.title};
  font-size: ${RFValue(14)}px;

  ${({ hasLeftIcon }) => hasLeftIcon && css`
    margin-left: 16px;
  `}
`;
