import React from 'react';
import { Platform, TouchableOpacityProps } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import { Container, Icon } from './styles';

interface Props extends TouchableOpacityProps {
  close?: boolean;
  isActive?: boolean;
  inverted?: boolean;
  onPress?: () => void;
}

export const BackButton = ({
  close = false, isActive, inverted = false, onPress, ...rest
}: Props) => {
  const navigation = useNavigation();

  const handleBack = () => {
    navigation.goBack();
  };

  const handleIcon = () => {
    if (!close) {
      return Platform.OS === 'android' ? "arrow-back" : "arrow-back-ios";
    }
    return 'close';
  };

  return (
    <Container isActive={isActive} {...rest} onPress={onPress || handleBack}>
      <Icon name={handleIcon()} inverted={inverted} />
    </Container>
  );
};
