import { getBottomSpace, getStatusBarHeight } from 'react-native-iphone-x-helper';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { RFValue } from 'react-native-responsive-fontsize';
import styled from 'styled-components/native';

export const Container = styled(KeyboardAwareScrollView).attrs({
  bounces: false,
})`
  flex: 1;
  padding: ${getStatusBarHeight() + 24}px 20px ${getBottomSpace()}px;
  background: ${({ theme }) => theme.colors.background};
`;

export const Title = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primaryBold};
  font-size: ${RFValue(24)}px;
  color: ${({ theme }) => theme.colors.title};
  margin: 32px 0 16px;
  text-align: center;
`;

export const SubTitle = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primaryRegular};
  font-size: ${RFValue(14)}px;
  color: ${({ theme }) => theme.colors.title};
  text-align: center;
  margin-bottom: 72px;
`;

export const ButtonContainer = styled.View`
  margin-top: 32px;
`;
