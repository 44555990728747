import { AxiosError } from "axios";
import { ProblemDetails } from "../dtos/problemDetailsDto";
import { ValidationProblemDetails } from "../dtos/validationProblemDetails";

function parseValidationKey(key: string) {
  return key.slice(2, key.length);
}

interface ProblemDetailError{
  type: 'ProblemDetail',
  details: string
}

interface ValidationProblemDetailsError{
  type: 'ValidationProblemDetails',
  errors: {[key:string]: string[]}
}

export function handleApiErrors(data:any) :
  ProblemDetailError | ValidationProblemDetailsError | null {
  // if (data?.response) {
  //   return null;
  // }

  const err = data as AxiosError;

  if (err?.response?.status !== 400 || err?.response?.data == null) {
    return null;
  }

  if (err.response.data.errors) {
    const validationProblem = err.response.data as ValidationProblemDetails;

    const parsedErrors = Object.keys(validationProblem.errors).reduce(
      (acc, key) => {
        acc[parseValidationKey(key)] = validationProblem.errors[key];

        return acc;
      },
     {} as {[key:string]: string[]},
    );

    return {
      type: 'ValidationProblemDetails',
      errors: parsedErrors,
    };
  }

  const problemDetail = err.response.data as ProblemDetails;

  return {
    type: 'ProblemDetail',
    details: problemDetail.detail,
  };
}
