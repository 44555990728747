import styled from 'styled-components/native';

export const InputContainer = styled.View`
  margin-bottom: 24px;
`;

export const ButtonContainer = styled.View`
  margin-top: 32px;
`;

export const ProfileContainer = styled.View`
  margin-bottom: 40px;
`;
