import React, {
  useCallback, useRef, useState,
} from 'react';
import {
  Alert, FlatList, RefreshControl,
} from 'react-native';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { Modalize } from 'react-native-modalize';

import { MaterialIcons } from '@expo/vector-icons';
import AddIcon from '../../assets/svg/AddIcon.svg';
import PushIcon from '../../assets/svg/pushAdSelect.svg';
import BannerIcon from '../../assets/svg/bannerAdSelect.svg';

import { HeaderContainer, Title } from '../Home/styles';

import {
  ButtonHeaderContainer,
  ButtonText,
  ButtonTextContainer,
  ButtonTextCTA,
  ButtonTextTitle,
  Container,
  Empty,
  EmptyButton,
  ModalizeSubTitle,
  ModalizeTitle,
  Separator,
} from './styles';
import { CampaignView } from './components/CampaignView';
import { apiServices } from '../../services/apiServices';
import { CampaignViewDto } from '../../services/dtos/viewDtos';

export const Search = () => {
  const ads = ['push-notification', 'banner'];
  const navigation = useNavigation();

  const modalRef = useRef<Modalize>(null);
  const [campaigns, setCampaigns] = useState<CampaignViewDto[]>([]);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const getAds = useCallback(() => {
    setIsRefreshing(true);
    apiServices.listCampaign()
      .then((response) => {
        if (response.success && response.data) setCampaigns(response.data?.result);
      })
      .catch(() => Alert.alert('Erro :/', 'no momento não foi possivel achar seus banners'))
      .finally(() => {
        setIsRefreshing(false);
      });
  }, []);

  useFocusEffect(
    useCallback(() => {
      apiServices.listCampaign().then((response) => {
        if (response.success && response.data) setCampaigns(response.data?.result);
      }).catch(() => Alert.alert('Erro :/', 'no momento não foi possivel achar seus banners'));
    }, []),
  );

  const handleNewAd = (item: string) => {
    modalRef.current?.close();
    navigation.navigate("GenericAd", item);
  };

  return (
    <Container>
      <HeaderContainer>
        <Title>Anúncios</Title>
        <ButtonHeaderContainer onPress={() => modalRef.current?.open()}>
          <AddIcon stroke="white" />
        </ButtonHeaderContainer>
      </HeaderContainer>
      <FlatList
        showsVerticalScrollIndicator={false}
        data={campaigns}
        keyExtractor={(item) => item.campaignId}
        contentContainerStyle={{
          padding: 20,
        }}
        renderItem={({ item }) => (
          <CampaignView campaign={item} />
        )}
        ListEmptyComponent={(
          <EmptyButton
            onPress={() => modalRef.current?.open()}
          >
            <Empty />
            <AddIcon stroke="white" />
          </EmptyButton>
        )}
        ItemSeparatorComponent={Separator}
        refreshControl={<RefreshControl refreshing={isRefreshing} onRefresh={getAds} />}
      />

      <Modalize
        ref={modalRef}
        adjustToContentHeight
        modalStyle={{
          paddingTop: 36,
          paddingHorizontal: 20,
        }}
      >
        <ModalizeTitle>Criar campanha</ModalizeTitle>
        <ModalizeSubTitle>Escolha uma modalidade de anúncio</ModalizeSubTitle>
        {ads.map((item) => (
          <ButtonTextContainer key={item} onPress={() => handleNewAd(item)}>
            {item === 'push-notification' ? <PushIcon /> : <BannerIcon /> }
            <ButtonText>
              <ButtonTextTitle>
                Anúncio
                {' '}
                {item}
              </ButtonTextTitle>
              <ButtonTextCTA>
                {item === 'push-notification' ? 'Notificação' : 'Banner na Home'}
              </ButtonTextCTA>
            </ButtonText>
            <MaterialIcons name="chevron-right" size={20} />
          </ButtonTextContainer>
        ))}
      </Modalize>
    </Container>
  );
};
