import React, { useState, useEffect } from 'react';
import { FlatList, Keyboard } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';
import { BackButton } from '../../../components/BackButton';
import { HelpButton } from '../../../components/HelpButton';
import { BaseInput } from '../../../components/inputs/BaseInput';

import { CommonQuestions, DataProps } from '../../../utils/getMainQuestions';

import { SearchIcon } from '../../SignUp/LastForm/styles';
import {
  Container,
  HeaderContainer,
  Separator,
  SubTitle,
  TipContainer,
  TipText,
  TipTextSemi,
  Title,
} from '../HelpCenter/styles';

export const QuestionHelp = () => {
  const [searchInput, setSearchInput] = useState('');
  const [mostSearched, setMostSearched] = useState<string[]>();
  const [data, setData] = useState<DataProps[]>([] as DataProps[]);
  const navigation = useNavigation();
  const opacity = useSharedValue(1);
  const scale = useSharedValue(90);

  useEffect(() => {
    setData(CommonQuestions);
    setMostSearched([
      "perdi a minha senha",
      "perguntas frequentes",
    ]);
  }, []);

  const styleHeader = useAnimatedStyle(() => ({
    opacity: opacity.value,
    height: scale.value,
  }));

  const handleFilter = () => data
    .filter((item) => item.title
      .toUpperCase()
      .includes(searchInput.toUpperCase()));

  const handlePress = (action: string | undefined, propsData?: DataProps) => {
    if (action) navigation.navigate(action, propsData && propsData);
  };

  return (
    <Container onPress={Keyboard.dismiss}>

      <HeaderContainer>
        <BackButton />
        <Animated.View style={styleHeader}>
          <Title>Central de ajuda</Title>
          <SubTitle>Perguntas frequentes</SubTitle>
        </Animated.View>
        <BaseInput
          onChangeText={setSearchInput}
          placeholder="Procure suas dúvidas"
          leftIcon={<SearchIcon />}
        />
        <TipContainer>
          <TipText>
            Topicos mais procurados:
            <TipTextSemi>
              {" "}
              {mostSearched?.map((item) => `${item} `)}
            </TipTextSemi>
          </TipText>
        </TipContainer>
      </HeaderContainer>
      <FlatList
        bounces={false}
        alwaysBounceVertical={false}
        onScroll={({ nativeEvent }) => {
          if (nativeEvent.contentOffset.y > 10) {
            opacity.value = withTiming(0);
            scale.value = withTiming(0);
          } if (nativeEvent.contentOffset.y < 10) {
            opacity.value = withTiming(1);
            scale.value = withTiming(90);
          }
        }}
        scrollEventThrottle={16}
        data={handleFilter()}
        keyExtractor={(item) => item.title}
        renderItem={({ item }) => (
          <HelpButton
            title={item.title}
            subTitle={item.subTitle}
            hasArrowIcon
            onPress={() => handlePress(item.action, item)}
          />
        )}
        contentContainerStyle={{ paddingVertical: 32 }}
        ItemSeparatorComponent={Separator}
      />
    </Container>
  );
};
