import React, { useState, useEffect } from 'react';
import { Feather } from '@expo/vector-icons';
import { useForm, useWatch } from 'react-hook-form';
import { useTheme } from 'styled-components/native';
import { useNavigation, useRoute } from '@react-navigation/native';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { AxiosError } from 'axios';
import { BackButton } from '../../../components/BackButton';
import { AnimatedPlaceholderInputForm } from '../../../components/inputs/AnimatedPlaceholderInputForm';
import { AnimatedPlaceholderMaskedInputForm } from '../../../components/inputs/AnimatedPlaceholderMaskedInputForm';
import { Button } from '../../../components/Button';
import { CheckBox } from '../../../components/CheckBox';
import { Pagination } from '../../../components/Pagination';

import { FirstFormData } from '../FirstForm';

import BrasilFlag from '../../../assets/svg/BrasilFlag.svg';

import { EyeContainer } from '../../SignIn/SignInEmail/styles';
import {
  ProgressContainer, Container, InputContainer, SectionText, Title,
} from '../FirstForm/styles';
import {
  BoldText, CheckBoxContainer, PromText,
} from './styles';
import { api } from '../../../services/axiosInstances';

interface Props {
  email: string;
  phone: string;
  password: string;
  confirmPassword: string;
}

const schema = Yup.object().shape({
  email: Yup
    .string()
    .email('Insira um email válido')
    .required('por favor insira um e-mail'),
  phone: Yup
    .string()
    .min(14, 'Por favor insira um telefone válido com DDD'),
  password: Yup.string().min(8, 'Mínimo de 8 caracteres'),
});

export const SecondForm = () => {
  const navigation = useNavigation();
  const theme = useTheme();
  const { params } = useRoute();
  const firstFormData = params as FirstFormData;

  const [isLoading, setIsLoading] = useState(false);
  const [securePassword, setSecurePassword] = useState(true);
  const [securePassword2, setSecurePassword2] = useState(true);
  const [showFlat, setShowFlag] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const { control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });
  const formInputs = useWatch({ control });
  const handleEye = () => {
    setSecurePassword((oldState) => !oldState);
  };

  const handleEye2 = () => {
    setSecurePassword2((oldState) => !oldState);
  };

  const handleCheck = () => {
    setIsChecked((oldState) => !oldState);
  };

  const errorValidator = (value: AxiosError): string => {
    const error = value.response?.data;
    if (error.errors) {
      return Object.values(error.errors)[0][0];
    }
    return error.detail;
  };

  const handleCreateAccount = async (form: Props) => {
    const data = {
      name: firstFormData.name,
      email: form.email,
      password: form.password,
      phone: form.phone.replace(/([\(|\)|\-| ])/g, ''),
      cnpj: firstFormData.cnpj,
      promotional_emails: isChecked,
    };

    if (form.password !== form.confirmPassword) {
      setPasswordError('As senhas não conferem');
      return;
    }

    try {
      setIsLoading(true);
      await api.post('/users/validate/email', { email: data.email });
      await api.post('/users/validate/phone', { phone: data.phone });
      navigation.navigate('LastForm', data);
    } catch (error) {
      const err = error as AxiosError;
      const message = errorValidator(err);
      setEmailError(message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (formInputs.phone && formInputs.phone.length >= 3) {
      setShowFlag(true);
    } else {
      setShowFlag(false);
    }
  }, [formInputs]);

  return (
    <Container>
      <ProgressContainer>
        <Pagination hasSecondActive />
      </ProgressContainer>
      <BackButton />
      <Title>Finalizar conta</Title>
      <InputContainer>
        <AnimatedPlaceholderInputForm
          name="email"
          control={control}
          placeholder="Insira seu e-mail"
          keyboardType="email-address"
          autoCorrect={false}
          autoCapitalize="none"
          error={errors?.email?.message || emailError}
        />
      </InputContainer>
      <AnimatedPlaceholderMaskedInputForm
        name="phone"
        control={control}
        placeholder="Digite seu Telefone"
        type="cel-phone"
        leftIcon={
          showFlat && <BrasilFlag />
        }
        error={errors?.phone?.message}
      />
      <SectionText>Criar uma senha</SectionText>
      <InputContainer>
        <AnimatedPlaceholderInputForm
          control={control}
          name="password"
          placeholder="Digite sua senha"
          secureTextEntry={securePassword}
          rightIcon={(
            <EyeContainer onPress={handleEye}>
              <Feather name={securePassword ? "eye" : "eye-off"} size={20} color={theme.colors.subtitle} />
            </EyeContainer>
          )}
          autoCorrect={false}
          error={errors?.password?.message}
        />
      </InputContainer>
      <AnimatedPlaceholderInputForm
        control={control}
        name="confirmPassword"
        placeholder="Confirme sua senha"
        secureTextEntry={securePassword2}
        rightIcon={(
          <EyeContainer onPress={handleEye2}>
            <Feather name={securePassword2 ? "eye" : "eye-off"} size={20} color={theme.colors.subtitle} />
          </EyeContainer>
          )}
        autoCorrect={false}
        error={errors?.password?.message || passwordError}

      />
      <CheckBoxContainer onPress={handleCheck}>
        <CheckBox isActive={isChecked} onPress={handleCheck} />
        <PromText>
          Receba emails de promoções dos
          {'\n'}
          restaurantes.
          {' '}
          <BoldText>
            Cancele a qualquer momento.
          </BoldText>
        </PromText>
      </CheckBoxContainer>
      <Button
        title="Criar conta"
        isInactive={!formInputs.email || !formInputs.phone || !formInputs.password || isLoading}
        onPress={handleSubmit(handleCreateAccount)}
        isLoading={isLoading}
      />
    </Container>
  );
};
