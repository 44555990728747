import React from 'react';

import { Container, PaginationContainer } from './styles';

interface Props {
  hasFirstActive?: boolean;
  hasSecondActive?: boolean;
  hasThird?: boolean;
  hasThirdActive?: boolean;
}

export const Pagination = ({
  hasFirstActive = false, hasSecondActive = false, hasThird = false, hasThirdActive = false,
}:Props) => (
  <Container>
    <PaginationContainer active={hasFirstActive} />
    <PaginationContainer active={hasSecondActive} />
    {hasThird
    && <PaginationContainer active={hasThirdActive} />}
  </Container>
);
