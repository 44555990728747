import { AxiosResponse } from "axios";
import { DefaultResponseDto } from "../dtos/defaultResponseDto";
import { handleApiErrors } from "./handleApiErrors";

export async function secureCommandRequests<T = any>(request: Promise<AxiosResponse<T>>) :
Promise<DefaultResponseDto<T>> {
  try {
    const response = await request;

    return {
      success: true,
      data: response?.data ?? null,
    } as DefaultResponseDto<T>;
  } catch (err) {
    const error = handleApiErrors(err);
    if (error === null) {
      return {
        success: false,
        detail: 'Ocorreu um erro inesperado',
      } as DefaultResponseDto;
    }
    if (error.type === 'ValidationProblemDetails') {
      return {
        success: false,
        detail: 'Ocorreu um erro de validação',
        errorMessage: error.errors,
      } as DefaultResponseDto;
    }
    return {
      success: false,
      detail: error.details,
    } as DefaultResponseDto;
  }
}
