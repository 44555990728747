/* eslint-disable no-param-reassign */
import { DefaultFilter } from "../dtos/filterDtos";

const parseValidationErrorsToString = (data: {[key: string]: string[]}) => Object.keys(data).reduce((acc, key) => {
  // eslint-disable-next-line no-param-reassign
  acc += ` ${data[key].join(', ')}`;
  return acc;
}, '');

const parseFilterToQuery = (filter: DefaultFilter) => {
  const keys = Object.keys(filter);
  const query = keys.reduce((acc, key) => {
    const value = filter[key];
    if (value !== '' && value !== null) {
      if (acc === '') acc = `${key}=${value.toString()}`;
      else acc += `&${key}=${value.toString()}`;
    }

    return acc;
  }, '');

  return query;
};
export {
  parseValidationErrorsToString,
  parseFilterToQuery,
};
