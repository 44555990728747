export default {
  colors: {
    title: '#1f1f1f',
    subtitle: '#666666',
    secondaryBackground: '#f5f5f5f5',
    primary: '#52AD6E',
    error: '#ee3020',
    background: '#fff',
    backgroundTitle: '#001908',
    gray: '#f0f0f0',
    gray100: '#DBDBDB',
    selected: '#F7FFF9',
    selectedRed: '#FFF7F7',
    green50: '#f0f6f2',
    green100: '#E0FFE9',
    green300: '#8CD4A3',
    green600: '#467D57',
  },

  fonts: {
    primaryBold: 'Poppins_700Bold',
    primarySemiBold: 'Poppins_600SemiBold',
    primaryMedium: 'Poppins_500Medium',
    primaryRegular: 'Poppins_400Regular',
  },
};
