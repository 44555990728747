import React from 'react';
import { TouchableOpacityProps } from 'react-native';

import { Container, Icon, Title } from './styles';

interface Props extends TouchableOpacityProps {
  title: string;
  disableArrow?: boolean;
}

export const Button = ({ title, disableArrow = false, ...rest }: Props) => (
  <Container {...rest}>
    <Title>{title}</Title>
    {
      !disableArrow
      && <Icon />
    }
  </Container>
);
