import React, { useState, useEffect, useRef } from 'react';
import { FlatList, Keyboard } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Modalize } from 'react-native-modalize';

import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';
import { BackButton } from '../../../components/BackButton';
import { HelpButton } from '../../../components/HelpButton';
import { BaseInput } from '../../../components/inputs/BaseInput';

import Phone from '../../../assets/svg/Phone.svg';
import Email from '../../../assets/svg/Email.svg';

import { DataProps, QuestionData, SvgHandler } from '../../../utils/getMainQuestions';

import { SearchIcon } from '../../SignUp/LastForm/styles';
import {
  Container,
  HeaderContainer,
  Separator,
  SubTitle,
  TipContainer,
  TipText,
  TipTextSemi,
  Title,
  VisibleModalContainer,
  ModalTitle,
  ModalSubTitle,
  ActionButtonContainer,
  ActionButton,
  ActionText,
  Modal,
} from './styles';

export const HelpCenter = () => {
  const [searchInput, setSearchInput] = useState('');
  const [mostSearched, setMostSearched] = useState<string[]>();
  const [data, setData] = useState<DataProps[]>([] as DataProps[]);
  const navigation = useNavigation();
  const modalRef = useRef<Modalize>(null);
  const opacity = useSharedValue(1);
  const scale = useSharedValue(90);

  useEffect(() => {
    setData(QuestionData);
    setMostSearched([
      "perdi a minha senha",
      "perguntas frequentes",
    ]);
  }, []);

  const styleHeader = useAnimatedStyle(() => ({
    opacity: opacity.value,
    height: scale.value,
  }));

  const handleFilter = () => data
    .filter((item) => item.title
      .toUpperCase()
      .includes(searchInput.toUpperCase()));

  const handlePress = (action: string | undefined) => {
    if (action) {
      if (action === 'Fale Conosco') {
        modalRef.current?.open();
      } else {
        navigation.navigate(action);
      }
    }
  };

  return (
    <Container onPress={Keyboard.dismiss}>
      <HeaderContainer>
        <BackButton />
        <Animated.View style={styleHeader}>
          <Title>Central de ajuda</Title>
          <SubTitle>Como podemos te ajudar</SubTitle>
        </Animated.View>
        <BaseInput
          onChangeText={setSearchInput}
          placeholder="Procure suas dúvidas"
          leftIcon={<SearchIcon />}
        />
        <TipContainer>
          <TipText>
            Topicos mais procurados:
            <TipTextSemi>
              {" "}
              {mostSearched?.map((item) => `${item} `)}
            </TipTextSemi>
          </TipText>
        </TipContainer>
      </HeaderContainer>
      <FlatList
        bounces={false}
        alwaysBounceVertical={false}
        onScroll={({ nativeEvent }) => {
          if (nativeEvent.contentOffset.y > 10) {
            opacity.value = withTiming(0);
            scale.value = withTiming(0);
          } if (nativeEvent.contentOffset.y < 10) {
            opacity.value = withTiming(1);
            scale.value = withTiming(90);
          }
        }}
        scrollEventThrottle={16}
        data={handleFilter()}
        keyExtractor={(item) => item.title}
        renderItem={({ item }) => (
          <HelpButton
            title={item.title}
            leftIcon={SvgHandler(item.leftIcon)}
            subTitle={item.subTitle}
            onPress={() => handlePress(item.action)}
          />
        )}
        contentContainerStyle={{ paddingVertical: 32 }}
        ItemSeparatorComponent={Separator}
      />
      <Modal ref={modalRef}>
        <VisibleModalContainer>
          <ModalTitle>Fale conosco</ModalTitle>
          <ModalSubTitle>
            Entre em contato com o nosso
            {'\n'}
            suporte por um desses meios
          </ModalSubTitle>
          <ActionButtonContainer>
            <ActionButton>
              <Phone />
              <ActionText>Ligar</ActionText>
            </ActionButton>
            <ActionButton>
              <Email />
              <ActionText>E-mail</ActionText>
            </ActionButton>
          </ActionButtonContainer>
        </VisibleModalContainer>
      </Modal>
    </Container>
  );
};
