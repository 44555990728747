import React, { ReactNode } from 'react';
import { TouchableOpacityProps } from 'react-native';

import {
  Container, Icon, SubTitle, TextContainer, Title,
} from './styles';

interface Props extends TouchableOpacityProps {
  title: string;
  subTitle: string;
  hasArrowIcon?: boolean;
  leftIcon?: ReactNode;
}

export const HelpButton = ({
  title, hasArrowIcon = false, subTitle, leftIcon, ...rest
}: Props) => (
  <Container {...rest}>
    {!!leftIcon && leftIcon}
    <TextContainer leftIcon={!!leftIcon}>
      <Title hasArrowIcon={hasArrowIcon}>{title}</Title>
      <SubTitle hasArrowIcon={hasArrowIcon}>{subTitle}</SubTitle>
    </TextContainer>
    {hasArrowIcon && <Icon />}
  </Container>
);
