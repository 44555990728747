import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  getMediaLibraryPermissionsAsync,
  launchImageLibraryAsync,
  requestMediaLibraryPermissionsAsync,
} from 'expo-image-picker';
import { useAuth } from '../../hooks/auth';

import { GenericHeader } from '../../components/GenericHeader';
import { AnimatedPlaceholderInput } from '../../components/inputs/AnimatedPlaceholderInput';
import { ProfileBlock } from '../../components/ProfileBlock';
import { Button } from '../../components/Button';
import { AnimatedPlaceholderMaskedInput } from '../../components/inputs/AnimatedPlaceholderMaskedInput';

import BrasilFlag from '../../assets/svg/BrasilFlag.svg';

import { Container } from '../RestaurantSugestion/styles';
import { ButtonContainer, InputContainer, ProfileContainer } from './styles';

export const MyData = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [image, setImage] = useState('');
  const { user } = useAuth();

  useEffect(() => {
    setName(user.name);
    setEmail(user.email);
    setPhone(user.cellphone);
  }, [user.name, user.email, user.cellphone]);

  const handlePickImage = async () => {
    const { granted } = await getMediaLibraryPermissionsAsync();
    if (!granted) {
      await requestMediaLibraryPermissionsAsync();
    } else {
      const imageResponse = await launchImageLibraryAsync();
      if (!imageResponse.cancelled) {
        setImage(imageResponse.uri);
      }
    }
  };

  return (
    <Container>
      <GenericHeader title="Meus dados" />
      <ProfileContainer>
        <ProfileBlock
          changePhoto
          title="Foto de perfil"
          subtitle="Escolha uma foto que te represente"
          onPress={handlePickImage}
          image={image}
        />
      </ProfileContainer>
      <InputContainer>
        <AnimatedPlaceholderInput
          placeholder="Nome completo"
          animatedValue={name}
          onChangeText={setName}
        />
      </InputContainer>
      <InputContainer>
        <AnimatedPlaceholderInput
          placeholder="Data de nascimento"
          animatedValue={moment(user.birth_date).format('DD/MM/YYYY')}
          isEnabled={false}
        />
      </InputContainer>
      <InputContainer>
        <AnimatedPlaceholderInput
          placeholder="E-mail"
          animatedValue={email}
          onChangeText={setEmail}
        />
      </InputContainer>
      <AnimatedPlaceholderMaskedInput
        placeholder="Digite seu Telefone"
        type="cel-phone"
        animatedValue={phone}
        onChangeText={setPhone}
        leftIcon={
          <BrasilFlag />
        }
      />
      <ButtonContainer>
        <Button title="Atualizar" />
      </ButtonContainer>
    </Container>
  );
};
