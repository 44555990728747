import { MaterialIcons } from '@expo/vector-icons';
import { RFValue } from 'react-native-responsive-fontsize';
import styled from 'styled-components/native';

export const Container = styled.TouchableOpacity`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;

export const Title = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primaryMedium};
  font-size: ${RFValue(14)}px;
  color: ${({ theme }) => theme.colors.subtitle};
`;

export const Icon = styled(MaterialIcons).attrs({
  name: 'chevron-right',
})`
  font-size: 24px;
  color: ${({ theme }) => theme.colors.subtitle};
`;
