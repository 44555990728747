import styled, { css } from "styled-components/native";
import { RFValue } from "react-native-responsive-fontsize";

interface Props {
  isInactive?: boolean;
  isInverted?: boolean;
  isTextSpecial?: boolean;
}

export const Container = styled.TouchableOpacity<Props>`
  width: 100%;
  border-width: 1px;
  height: 60px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.primary};
  border-color: transparent;

  ${({ isInactive, theme }) => isInactive && css`
      align-items: center;
      border-color: ${theme.colors.subtitle};
      background: transparent;
    `};

  ${({ isInverted, theme, isInactive }) => isInverted && !isInactive && css`
    align-items: center;
    background: ${theme.colors.title};
    
    `};

`;

export const Title = styled.Text<Props>`
  font-family: ${({ theme }) => theme.fonts.primarySemiBold};
  font-size: ${RFValue(16)}px;

  color: ${({ theme, isInactive }) => (isInactive ? theme.colors.subtitle : theme.colors.background)};

  ${({ isInverted, theme, isInactive }) => isInverted && css`
    color: ${isInactive ? theme.colors.subtitle : theme.colors.primary};
    `};

    ${({ isTextSpecial, theme }) => isTextSpecial && css`
    color: ${theme.colors.specialContent};
    
    `};

`;
