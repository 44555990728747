import { RFValue } from 'react-native-responsive-fontsize';
import styled from 'styled-components/native';

interface Props {
  isActive: boolean;
}

export const Container = styled.View`
`;

export const ProgressTextContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ProgressText = styled.Text<Props>`
  font-family: ${({ theme, isActive }) => (isActive ? theme.fonts.primarySemiBold : theme.fonts.primaryRegular)};
  font-size: ${RFValue(12)}px;
  color: ${({ theme, isActive }) => (isActive ? theme.colors.title : theme.colors.subtitle)};
`;

export const Bar = styled.View`
  margin-top: 20px;
  background: #ebfef1;
  border-radius: 8px;
  height: 18px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
`;

export const ContentBar = styled.View`
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 8px;
  height: 18px;
  position: absolute;
`;

export const Dots = styled.Pressable<Props>`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: ${({ theme, isActive }) => (isActive ? theme.colors.background : theme.colors.green600)};
`;
