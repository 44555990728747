import styled from "styled-components/native";
import { RFValue } from "react-native-responsive-fontsize";

export const CheckBoxContainer = styled.TouchableOpacity`
  margin: 24px 0 32px;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`;

export const PromText = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primaryRegular};
  font-size: ${RFValue(12)}px;
  color: ${({ theme }) => theme.colors.title};
`;

export const BoldText = styled(PromText)`
  font-family: ${({ theme }) => theme.fonts.primarySemiBold};
`;
