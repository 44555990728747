import React from 'react';
import { CardStyleInterpolators, createStackNavigator } from '@react-navigation/stack';

import { FirstStep } from '../screens/PasswordRecovery/FirstStep';
import { SecondStep } from '../screens/PasswordRecovery/SecondStep';
import { LastStep } from '../screens/PasswordRecovery/LastStep';
import { Confirmation } from '../screens/PasswordRecovery/Confirmation';

const { Navigator, Screen } = createStackNavigator();

export const PasswordRecoveryRoutes = () => (
  <Navigator screenOptions={{
    headerShown: false,
    cardStyleInterpolator: CardStyleInterpolators.forVerticalIOS,
    detachPreviousScreen: false,
  }}
  >

    <Screen name="FirstStep" component={FirstStep} />
    <Screen name="SecondStep" component={SecondStep} />
    <Screen name="LastStep" component={LastStep} />
    <Screen name="Confirmation" component={Confirmation} />

  </Navigator>
);
