interface Props {
  title: string;
  icon: 'restaurant' | 'eye' | 'banner' | 'notification'
}

export const periodData: Props[] = [
  {
    title: 'Visualizaram\no perfil',
    icon: 'restaurant',
  },
  {
    title: 'Visualizaram\no cardápio',
    icon: 'eye',
  },
  {
    title: 'Cliques no\nbanner',
    icon: 'banner',
  },
  {
    title: 'Cliques na\nnotificação',
    icon: 'notification',
  },
];

export const periodDates = [
  'Hoje',
  'Ontem',
  '7 dias',
  '14 dias',
  '30 dias',
];
