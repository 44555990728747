import { getStatusBarHeight } from 'react-native-iphone-x-helper';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import styled from 'styled-components/native';

export const Container = styled(KeyboardAwareScrollView)`
  flex: 1;
  background: ${({ theme }) => theme.colors.background};
  padding: ${getStatusBarHeight() + 37}px 20px 0;
`;

export const InputView = styled.View`
  margin: 24px 0 32px; 
`;
