import styled from 'styled-components/native';

export const Container = styled.View`
  flex: 1;
  background: ${({ theme }) => theme.colors.background};
  justify-content: center;
  padding: 0 20px;
  align-items: center;
`;

export const Box = styled.View``;

export const ButtonContainer = styled.View`
  position: absolute;
  width: 100%;
  bottom: 80px;
`;

export const TitleContainer = styled.View`
  margin: 40px 0 32px;
`;
