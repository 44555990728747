import React, { ReactNode, useState } from 'react';
import { TextInputProps } from 'react-native';
import { useTheme } from 'styled-components/native';

import { Container, Input } from './styles';

interface Props extends TextInputProps {
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
}

export const BaseInput = ({
  leftIcon,
  rightIcon,
  ...rest
}: Props) => {
  const [isFocused, setFocused] = useState(false);
  const theme = useTheme();

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  return (
    <Container isFocused={isFocused}>
      {leftIcon && leftIcon}
      <Input
        hasLeftIcon={!!leftIcon}
        onBlur={handleBlur}
        onFocus={handleFocus}
        keyboardAppearance="dark"
        placeholderTextColor={theme.colors.subtitle}
        selectionColor={theme.colors.primary}
        {...rest}
      />
      {rightIcon && rightIcon}
    </Container>
  );
};
