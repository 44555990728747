import styled from "styled-components/native";
import { getBottomSpace, getStatusBarHeight } from "react-native-iphone-x-helper";
import { RFValue } from "react-native-responsive-fontsize";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

export const Container = styled(KeyboardAwareScrollView).attrs({
  bounces: false,
})`
  flex: 1;
  background: ${({ theme }) => theme.colors.background};
  padding: ${getStatusBarHeight() + 24}px 20px ${getBottomSpace()}px;
`;

export const Title = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primaryBold};
  font-size: ${RFValue(24)}px;
  color: ${({ theme }) => theme.colors.title};
  margin: 32px 0;
`;

export const InputContainer = styled.View`
  margin-bottom: 24px;
`;

export const SectionText = styled(Title)`
  margin-bottom: 40px;
  font-size: ${RFValue(18)}px;
`;

export const IdentifyContainer = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;

export const ProgressContainer = styled.View`
  width: 50%;
  align-self: center;
`;

export const ButtonContainer = styled.View`
  margin: 32px 0 48px;
`;

export const TermsText = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primaryRegular};
  font-size: ${RFValue(12)}px;
  color: ${({ theme }) => theme.colors.title};
  text-align: center;

`;

export const TermsButtonText = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primarySemiBold};
  text-decoration: underline; 
  color: ${({ theme }) => theme.colors.primary};
  text-decoration-color: ${({ theme }) => theme.colors.primary}; 
`;
