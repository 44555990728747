import { api } from "./axiosInstances";
import { CreateCampaignDto } from "./dtos/createDtos";
import { DefaultResponseDto } from "./dtos/defaultResponseDto";
import { FilterCampaignViewDto } from "./dtos/filterDtos";
import { PageableResult } from "./dtos/pageableResult";
import { CampaignViewDto, CompanyViewDto } from "./dtos/viewDtos";
import { secureCommandRequests } from "./utils/secureCommandRequests";
import { parseFilterToQuery } from "./utils/serviceUtils";

export const apiServices = {
  createCampaign: async (data: CreateCampaignDto) :Promise<DefaultResponseDto> => {
    const result = await secureCommandRequests(
      api.post('/campaigns', data),
    );
    return result;
  },
  listCampaign: async (data?: FilterCampaignViewDto):
  Promise<DefaultResponseDto<PageableResult<CampaignViewDto>>> => {
    const filter = data ?? {
      name: '',
      startDate: '',
      endDate: '',
      orderBy: 'Name',
      orderDirection: 'ASC',
      page: 1,
      take: 10,
    };
    const result = await api.get<PageableResult<CampaignViewDto>>(`/campaigns?${parseFilterToQuery(filter)}`);
    return {
      success: true,
      data: result.data,
    };
  },
  getCurrentCompany: async (): Promise<DefaultResponseDto<CompanyViewDto>> => {
    const result = await api.get<CompanyViewDto>('/current-company');
    return {
      success: true,
      data: result.data,
    };
  },
  updateCurrentCompany: async (data: CompanyViewDto):
  Promise<DefaultResponseDto<CompanyViewDto>> => {
    const result = await secureCommandRequests(
      api.put('/current-company', data),
    );
    return result;
  },
};
