import React, { useEffect } from 'react';
import { Modal, TouchableOpacity } from 'react-native';
import Animated, {
  runOnJS, useAnimatedStyle, useSharedValue, withTiming,
} from 'react-native-reanimated';

import {
  CenterView,
  ItemBox,
  ItemCheck,
  ItemContainer,
  ItemTitle,
  ModalContainer,
  ModalContent,
  ModalDatesContainer,
  ModalHeader,
  ModalIcon,
  ModalTitle,
} from './styles';

interface Props {
  modalVisibility: boolean;
  close: () => void;
  periodDates: string[];
  handlePeriod: (value: number) => void;
  selectedItem: number;
}

const AnimatedModalContent = Animated.createAnimatedComponent(ModalContent);

export const ActivityModal = ({
  modalVisibility,
  close,
  periodDates,
  handlePeriod,
  selectedItem,
}: Props) => {
  const modalTranslate = useSharedValue(500);

  const animateModalStyle = useAnimatedStyle(() => ({
    transform: [
      { translateY: modalTranslate.value },
    ],
  }));

  useEffect(() => {
    if (modalVisibility) {
      modalTranslate.value = withTiming(0);
    }
  }, [modalTranslate, modalVisibility]);

  const closeModal = () => {
    modalTranslate.value = withTiming(500, {
      duration: 300,
    }, () => {
      runOnJS(close)();
    });
  };

  return (
    <Modal
      visible={modalVisibility}
      transparent
      animationType="fade"
    >
      <ModalContainer onPress={closeModal}>
        <AnimatedModalContent style={animateModalStyle}>
          <ModalHeader>
            <TouchableOpacity onPress={closeModal}>
              <ModalIcon />
            </TouchableOpacity>
            <ModalTitle>Escolha uma data</ModalTitle>
            <CenterView />
          </ModalHeader>
          <ModalDatesContainer>
            {periodDates.map((item, index) => (
              <ItemContainer
                checked={selectedItem === index}
                key={String(index)}
                onPress={() => handlePeriod(index)}
              >
                <ItemTitle checked={selectedItem === index}>{item}</ItemTitle>
                <ItemBox>
                  {
                      selectedItem === index && (
                        <ItemCheck />
                      )
                    }
                </ItemBox>
              </ItemContainer>
            ))}
          </ModalDatesContainer>
        </AnimatedModalContent>
      </ModalContainer>
    </Modal>
  );
};
