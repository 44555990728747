import React, { useState, useEffect } from 'react';
import { useRoute } from '@react-navigation/native';

import { ScrollView } from 'react-native';
import { BackButton } from '../../../components/BackButton';

import { DataProps } from '../../../utils/getMainQuestions';

import {
  HeaderContainer,
  SubTitle,
  Title,
} from '../HelpCenter/styles';
import {
  ContentContainer, Content, ContentTitle, Container,
} from './styles';

export const ContentHelp = () => {
  const [data, setData] = useState<DataProps>({} as DataProps);
  const { params } = useRoute() as { params:DataProps};
  useEffect(() => {
    setData(params);
  }, [params]);

  return (
    <Container>
      <HeaderContainer>
        <BackButton />
        <Title>Central de ajuda</Title>
        <SubTitle>{params?.title}</SubTitle>
      </HeaderContainer>
      <ScrollView>
        <ContentContainer>
          <ContentTitle>{data.contentTitle}</ContentTitle>
          <Content>{data.content}</Content>
        </ContentContainer>
      </ScrollView>
    </Container>
  );
};
