import styled from 'styled-components/native';
import { getStatusBarHeight } from 'react-native-iphone-x-helper';
import { RFValue } from 'react-native-responsive-fontsize';

export const Container = styled.View`
  flex: 1;
  background: ${({ theme }) => theme.colors.background};
  padding: ${getStatusBarHeight() + 24}px 20px 0px;
`;

export const ContentContainer = styled.View`
  background: ${({ theme }) => theme.colors.gray};
  padding: 24px 32px 30px;
  border-radius: 10px;
`;

export const ContentTitle = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primarySemiBold};
  font-size: ${RFValue(16)}px;
  color: ${({ theme }) => theme.colors.title};
  margin-bottom: 16px;
`;

export const Content = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primaryRegular};
  font-size: ${RFValue(15)}px;
  color: ${({ theme }) => theme.colors.subtitle};
  text-align: left;
`;
