import { useNavigation, useRoute } from '@react-navigation/native';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { BackButton } from '../../../components/BackButton';
import { VerificationCodeInput } from '../../../components/inputs/VerificationCodeInput';

import {
  ButtonContainer,
  Container,
  Title,
  SubTitle,
} from '../FirstStep/styles';
import { EmailText, WrongEmailText } from './styles';

const timerInSeconds = 120;

export const SecondStep = () => {
  const navigation = useNavigation();
  const { params } = useRoute();
  const [inputValue, setInputValue] = useState('');
  const [counterDown, setCounterDown] = useState('');
  const [showTimer, setShowTimer] = useState(true);

  const handleResend = () => {
    setShowTimer(true);
  };
  const handleGoBack = () => {
    navigation.goBack();
  };

  useEffect(() => {
    let timer = timerInSeconds;
    const interval = setInterval(() => {
      if (timer > 0) {
        timer -= 1;
      }

      if (timer >= 0) {
        const countDown = moment().startOf('day').second(timer).format("mm:ss");
        setCounterDown(countDown);
      }

      if (timer === 0) {
        setShowTimer(false);
        timer = timerInSeconds;
      }
    }, 1000);
    if (!showTimer) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [showTimer]);

  useEffect(() => {
    const handlePress = () => {
      navigation.navigate('LastStep');
    };

    if (inputValue.length === 6) {
      handlePress();
    }
  }, [inputValue.length, navigation]);

  return (
    <Container>
      <BackButton close />
      <Title>Enviamos um código</Title>
      <SubTitle>
        Digite o código que enviamos para
        {'\n'}
        <EmailText>{params}</EmailText>
        .
        {' '}
        <WrongEmailText onPress={handleGoBack}>
          E-mail
          {'\n'}
          errado ?
        </WrongEmailText>
      </SubTitle>
      <VerificationCodeInput textValue={inputValue} onChangeText={setInputValue} />
      <ButtonContainer>
        <SubTitle style={{ marginBottom: 48 }}>
          Não recebeu o e-mail ?
          {'\n'}
          <EmailText>
            Procure na lixeira ou pasta de spam
          </EmailText>
        </SubTitle>
      </ButtonContainer>
      {
        showTimer
          ? (
            <SubTitle>
              Tente novamente em
              {' '}
              <WrongEmailText>
                {counterDown}
              </WrongEmailText>
            </SubTitle>
          )
          : (
            <WrongEmailText onPress={handleResend} style={{ textAlign: 'center' }}>Reenviar código</WrongEmailText>
          )
      }
    </Container>
  );
};
