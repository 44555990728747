import React from 'react';
import { TouchableOpacityProps } from 'react-native';
import { useAuth } from '../../hooks/auth';

import ChangePhoto from '../../assets/svg/ChangePhoto.svg';

import {
  Block,
  Container,
  Icon,
  ProfileText,
  ProfileView,
  SubTitle,
  Title,
  Group,
  OverlayPhoto,
  Image,
} from './styles';

interface Props extends TouchableOpacityProps {
  title: string;
  subtitle: string;
  hasArrow?: boolean;
  image?: string;
  changePhoto?: boolean;
}

export const ProfileBlock = ({
  title,
  subtitle, hasArrow = false, changePhoto = false, image, ...rest
}: Props) => {
  const { user } = useAuth();
  return (
    <Container
      activeOpacity={0.7}
      {...rest}
    >
      <Group>
        {
          changePhoto
            ? (
              <ProfileView>
                <OverlayPhoto>
                  <ChangePhoto />
                </OverlayPhoto>
                <ProfileText>{user.name[0]}</ProfileText>
                {
                  !!image && <Image source={{ uri: image }} />
                }
              </ProfileView>
            )
            : (
              <ProfileView>
                <ProfileText>{user.name[0]}</ProfileText>
                {
                  !!image && <Image source={{ uri: image }} />
                }
              </ProfileView>
            )
        }
        <Block>
          <Title>{title}</Title>
          <SubTitle>{subtitle}</SubTitle>
        </Block>
      </Group>
      {hasArrow
          && <Icon />}
    </Container>
  );
};
