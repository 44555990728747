import React from 'react';
import { CardStyleInterpolators, createStackNavigator } from '@react-navigation/stack';

import { Splash } from '../screens/Splash';
import { Welcome } from '../screens/Welcome';
import { SignInEmail } from '../screens/SignIn/SignInEmail';
import { SignInPassword } from '../screens/SignIn/SignInPassword';

import { SignUpRoutes } from './SignUpRoutes.routes';
import { HelpRoutes } from './HelpRoutes.routes';
import { PasswordRecoveryRoutes } from './PasswordRecoveryRoutes.routes';

const { Navigator, Screen, Group } = createStackNavigator();

export const Unlogged = () => (
  <Navigator
    screenOptions={{
      headerShown: false,
      cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
      detachPreviousScreen: false,
    }}
  >
    <Screen
      name="Splash"
      component={Splash}
    />

    <Group screenOptions={{
      gestureEnabled: false,
      cardStyleInterpolator: CardStyleInterpolators.forFadeFromCenter,
    }}
    >
      <Screen
        name="Welcome"
        component={Welcome}
      />
    </Group>

    <Screen
      name="SignIn"
      component={SignInEmail}
      options={{
        gestureEnabled: false,

      }}
    />

    <Screen
      name="SignInPassword"
      component={SignInPassword}
    />

    <Screen
      name="SignUp"
      component={SignUpRoutes}
      options={{ gestureEnabled: false }}
    />

    <Screen
      name="Help"
      component={HelpRoutes}
    />

    <Screen
      name="PasswordRecovery"
      component={PasswordRecoveryRoutes}
    />

  </Navigator>
);
