import React from 'react';
import { useAuth } from '../hooks/auth';

import { MainNavigation } from './MainNavigation.routes';
import { Unlogged } from './Unlogged.routes';

import { Container } from '../screens/Splash/styles';

export const Routes = () => {
  const { user, isLoading } = useAuth();

  if (isLoading) {
    return <Container />;
  }

  return user.userId
    ? <MainNavigation />
    : <Unlogged />;
};
