import React, { ReactNode } from 'react';
import { AuthProvider } from './auth';
import { FirstEntryProvider } from './firstEntry';

interface Props {
  children: ReactNode;
}

export const AppProvider = ({ children }: Props) => (
  <FirstEntryProvider>
    <AuthProvider>
      {children}
    </AuthProvider>
  </FirstEntryProvider>
);
