import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { Button } from '../../../components/Button';
import Success from '../../../assets/svg/Success.svg';
import { SubTitle, Title } from '../FirstStep/styles';

import {
  Container, ButtonContainer, TitleContainer, Box,
} from './styles';

export const Confirmation = () => {
  const navigation = useNavigation();
  const handleLogin = () => {
    navigation.navigate('SignIn');
  };
  return (
    <Container>
      <Success />
      <Box>
        <TitleContainer>
          <Title>
            Senha recuperada
            {'\n'}
            com sucesso
          </Title>
        </TitleContainer>
        <SubTitle>
          A sua senha foi recriada com
          {'\n'}
          sucesso. Por favor use sua
          {'\n'}
          nova senha para fazer o login
        </SubTitle>
      </Box>
      <ButtonContainer>
        <Button onPress={handleLogin} title="Login" />
      </ButtonContainer>
    </Container>
  );
};
