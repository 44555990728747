import React from 'react';
import { CardStyleInterpolators, createStackNavigator } from '@react-navigation/stack';

import { Profile } from '../screens/Profile';
import { Settings } from '../screens/Settings';
import { RestaurantSugestion } from '../screens/RestaurantSugestion';
import { MyData } from '../screens/MyData';

const { Navigator, Screen } = createStackNavigator();

export const UserRoutes = () => (
  <Navigator screenOptions={{
    headerShown: false,
    detachPreviousScreen: false,
    cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
  }}
  >
    <Screen name="Main" component={Profile} />
    <Screen name="Settings" component={Settings} />
    <Screen name="RestaurantSugestion" component={RestaurantSugestion} />
    <Screen name="MyData" component={MyData} />
  </Navigator>
);
