import React from 'react';
import LockerBigger from '../assets/svg/LockerBigger.svg';
import Question from '../assets/svg/Question.svg';
import Headphone from '../assets/svg/Headphone.svg';

interface CommonProps {
  title: string;
  subTitle: string;
  contentTitle?: string;
  content?: string;
  leftIcon?: string;
  action?: string;
}
export interface DataProps extends CommonProps {
  data?: CommonProps[];
}

export const QuestionData: DataProps[] = [
  {
    title: 'Recuperar senha',
    subTitle: 'Recupere com seu e-mail',
    leftIcon: "Lock",
    action: 'PasswordRecovery',
  },
  {
    title: 'Perguntas Frequentes',
    subTitle: 'Diversas perguntas para te ajudar',
    leftIcon: "Question",
    action: "QuestionHelp",
  },
  {
    title: 'Fale conosco',
    subTitle: 'Entre em contato com a gente',
    leftIcon: "Headphone",
    action: "Fale Conosco",
  },
];

export const CommonQuestions: DataProps[] = [
  {
    title: 'Sobre o menu max',
    subTitle: 'Fique por dentro do app',
    action: 'AboutApp',
    data: [
      {
        title: 'O que é menu max?',
        subTitle: 'Conheça a história por trás do app',
        contentTitle: 'Sobre o menu max',
        action: 'ContentHelp',
        content: `MenuMax foi idealizado com o objetivo de facilitar a vida do público frequentador de bares, restaurantes, cafés, food trucks e outros estabelecimentos;\n\nO aplicativo trará informações valiosas para o cliente, promoções e conteúdo exclusivo além de um marketing dinâmico e direcionado facilitando a conversão de novos clientes e retenção dos atuais frequentadores.`,
      },
      {
        title: 'Como funciona o app',
        subTitle: 'Fique por dentro do app',
      },
      {
        title: 'Preciso gastar com algo?',
        subTitle: 'Valores',
      },
      {
        title: 'Como baixar o cardápio?',
        subTitle: 'Aprenda como usar o app',
      },
      {
        title: 'Posso pedir comida pelo app?',
        subTitle: 'Descubra as funcionalidades principais',
      },
    ],
  },
  {
    title: 'Politica de privacidade',
    subTitle: 'Fique por dentro do app',
  },
  {
    title: 'Sobre a minha conta',
    subTitle: 'Começando > Valores',
  },
];

export const SvgHandler = (item: string | undefined) => {
  switch (item) {
    case 'Lock':
      return <LockerBigger />;
    case 'Question':
      return <Question />;
    case 'Headphone':
      return <Headphone />;
    default:
      return 0;
  }
};
