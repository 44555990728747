import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { useBlockBackButton } from '../../hooks/blockBackButton';

import { Button } from '../../components/Button';
import { InlineButtonMessage } from '../../components/InlineButtonMessage';

import welcomeImage from '../../assets/images/welcomeImage/welcomeImage.png';
import {
  ButtonContainer,
  Container,
  Image,
  SubContainer,
  SubTitle,
} from './styles';

export const Welcome = () => {
  const navigation = useNavigation();

  const handleSignIn = () => {
    navigation.navigate('SignIn');
  };

  const handleSignUp = () => {
    navigation.navigate('SignUp');
  };

  useBlockBackButton();

  return (
    <Container>
      <Image resizeMode="contain" source={welcomeImage} />
      <SubContainer>
        <SubTitle>
          Seja visto, seja lembrado e
          {'\n'}
          apareça na frente
        </SubTitle>
        <ButtonContainer>
          <Button title="Começar agora" onPress={handleSignUp} />
        </ButtonContainer>
        <InlineButtonMessage
          style={{ alignSelf: 'center' }}
          onPress={handleSignIn}
          message="Já tem uma conta?"
          buttonLabel="Entrar"
        />
      </SubContainer>
    </Container>
  );
};
