import React, { useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useForm, useWatch } from 'react-hook-form';
import { AxiosError } from 'axios';
import { useBlockBackButton } from '../../../hooks/blockBackButton';

import { Button } from '../../../components/Button';
import { AnimatedPlaceholderInputForm } from '../../../components/inputs/AnimatedPlaceholderInputForm';
import { InlineButtonMessage } from '../../../components/InlineButtonMessage';

import QuestionBox from '../../../assets/svg/QuestionBox.svg';

import {
  ButtonContainer,
  Container,
  HeaderEnd,
  HelpContainer,
  HelpText,
  InlineButtonContainer,
  InputContainer,
  Title,
} from './styles';
import { api } from '../../../services/axiosInstances';

interface Inputs {
  email: string;
}

export const SignInEmail = () => {
  const navigation = useNavigation();

  const { control, handleSubmit } = useForm();
  const [emailError, setEmailError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const inputsValues = useWatch({
    control,
  });

  const handleHelp = () => {
    navigation.navigate('Help');
  };

  const SignInForm = async (form: Inputs) => {
    setIsLoading(true);
    try {
      await api.post('/users/validate/email', { email: form.email });
      setEmailError('Email não encontrado');
    } catch (error) {
      const err = error as AxiosError;
      const errorString = Object.values(err.response?.data.errors)[0][0];
      if (errorString === 'Este email já está em uso.') {
        navigation.navigate('SignInPassword', { email: form.email });
        setEmailError('');
      } else {
        setEmailError(errorString);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignUp = () => {
    navigation.navigate('SignUp');
  };

  useBlockBackButton();

  return (
    <Container>
      <HeaderEnd>
        <HelpContainer onPress={handleHelp}>
          <HelpText>Preciso de ajuda</HelpText>
          <QuestionBox />
        </HelpContainer>
      </HeaderEnd>
      <Title>
        Bem vindo
        {'\n'}
        de volta
      </Title>
      <InputContainer>
        <AnimatedPlaceholderInputForm
          control={control}
          name="email"
          placeholder="Insira seu e-mail"
          keyboardType="email-address"
          autoCorrect={false}
          autoCapitalize="none"
          error={emailError}
        />
      </InputContainer>
      <ButtonContainer>
        <Button
          title="Continuar"
          isInactive={!inputsValues.email || isLoading}
          onPress={handleSubmit(SignInForm)}
          isLoading={isLoading}
        />
      </ButtonContainer>
      <InlineButtonContainer>
        <InlineButtonMessage
          message="Não tem uma conta?"
          buttonLabel="Criar conta"
          onPress={handleSignUp}
        />
      </InlineButtonContainer>
    </Container>
  );
};
