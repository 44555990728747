import React, { useRef, useState } from 'react';

import { Calendar, LocaleConfig } from 'react-native-calendars';
import {
  Modal, Platform, ScrollView, View,
} from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import { useTheme } from 'styled-components/native';
import { eachDayOfInterval } from 'date-fns/esm';
import { addDays, format, isEqual } from 'date-fns';
import { DateData as DateDataProps } from 'react-native-calendars/src/types';
import { Modalize } from 'react-native-modalize';
import { Button } from '../../../../components/Button';

import { ButtonContainer } from '../BasicData/styles';

import {
  ButtonContainerModal,
  ButtonDate,
  ButtonDateText,
  CleanButton,
  DateContainer,
  DateInput,
  DateInputContainer,
  DateModalText,
  ModalContainer,
  Title,
  ValueContainer,
} from './styles';
import { BaseInput } from '../../../../components/inputs/BaseInput';

export interface DateRangeArgs {
  startDate: Date;
  endDate: Date;
}

interface Props {
  handleSteps: () => void;
  setDateRange: (value: DateRangeArgs) => void;
  value: number;
  setValue: (value: number) => void;
  initialHour: string;
  setInitialHour: (value: string) => void;
  endHour: string;
  setEndHour: (value: string) => void;
}

interface MarkedDateProps {
  [date: string]: {
    color: string;
    textColor: string;
    disabled?: boolean;
    disableTouchEvent?: boolean;
  }
}

LocaleConfig.locales['pt-br'] = {
  monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
  monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
  dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
  dayNamesShort: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
  today: 'Hoje',
};
LocaleConfig.defaultLocale = 'pt-br';

export const DateData = ({
  handleSteps,
  setDateRange,
  value,
  setValue,
  initialHour,
  endHour,
  setEndHour,
  setInitialHour,
}: Props) => {
  const theme = useTheme();

  const [inputValue, setInputValue] = useState('');
  const [modalDates, setModalDates] = useState<MarkedDateProps>({});

  const modal = useRef<Modalize>(null);

  const [dates, setDates] = useState({
    startDate: 0,
    endDate: 0,
  });

  const handleDates = async () => {
    const data = {
      startDate: new Date(Object.keys(modalDates)[0]),
      endDate: new Date(Object.keys(modalDates)[Object.keys(modalDates).length - 1]),
    };
    setDateRange(data);
    setInputValue(`De ${format(data.startDate, 'dd/MM/yyyy')} - Até ${format(data.endDate, 'dd/MM/yyyy')}`);
    modal.current?.close();
  };

  const cleanDates = () => {
    setDates({ startDate: 0, endDate: 0 });
    setModalDates({});
    setInitialHour('');
    setEndHour('');
  };

  const handleChangeDate = (receivedDate: DateDataProps) => {
    let interval = {};
    let startDate = !dates.startDate ? receivedDate.timestamp : dates.startDate;
    const endDate = receivedDate.timestamp;
    if (startDate > endDate) {
      startDate = endDate;
    }
    setDates({ startDate, endDate });

    eachDayOfInterval({ start: new Date(startDate), end: new Date(endDate) })
      .forEach((item) => {
        const plataform = Platform.OS === 'ios' ? addDays(item, 1) : item;
        const date = format(plataform, 'yyyy-MM-dd');
        interval = {
          ...interval,
          [date]: {
            color: (isEqual(new Date(startDate), new Date(date))
                  || isEqual(new Date(endDate), new Date(date)))
              ? theme.colors.primary : theme.colors.green100,
            textColor: (isEqual(new Date(startDate), new Date(date))
              || isEqual(new Date(endDate), new Date(date)))
              ? theme.colors.background : theme.colors.title,
          },
        };
      });
    setModalDates(interval);
  };

  return (
    <>
      <Title>Escolha a data</Title>
      <ButtonDate
        onPress={() => modal.current?.open()}
      >
        <ButtonDateText>{inputValue || "Escolha a data do anúncio"}</ButtonDateText>
      </ButtonDate>
      <ValueContainer>
        <BaseInput
          editable={false}
          value={`valor total de: ${(value || 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`}
        />
      </ValueContainer>
      <ButtonContainer>
        <Button title="Criar anúncio" isInactive={!inputValue.length} onPress={handleSteps} />
      </ButtonContainer>

      <Modalize
        ref={modal}
        adjustToContentHeight
        withReactModal
      >

        {/* <ModalContainer onPress={() => setModalVisibility(false)}> */}
        <DateContainer>
          {/* <ScrollView> */}
          <Title>Selecione uma data</Title>
          <Calendar
            renderArrow={(direction) => (
              <MaterialIcons
                name={direction === 'left' ? 'arrow-back-ios' : 'arrow-forward-ios'}
                color={theme.colors.primary}
                size={16}
              />
            )}
            minDate={new Date().toDateString()}
            theme={{
              textDayStyle: {
                fontFamily: theme.fonts.primaryRegular,
                fontSize: 14,
                color: theme.colors.title,
              },
              textDayHeaderFontSize: 14,
              textDayHeaderFontFamily: theme.fonts.primaryRegular,
              textSectionTitleColor: theme.colors.title,
              textMonthFontFamily: theme.fonts.primaryMedium,
              textMonthFontSize: 16,
            }}
            markingType="period"
            markedDates={modalDates}
            onDayPress={handleChangeDate}
          />
          {/* <DateModalText>Horário inicial e final</DateModalText>
              <DateInputContainer>
                <DateInput
                  type="datetime"
                  options={{
                    format: 'HH:mm',
                  }}
                  placeholder="00:00"
                  value={initialHour}
                  onChangeText={setInitialHour}
                  isActive={initialHour?.length === 5}
                />
                <DateInput
                  type="datetime"
                  options={{
                    format: 'HH:mm',
                  }}
                  placeholder="00:00"
                  value={endHour}
                  onChangeText={setEndHour}
                  isActive={endHour?.length === 5}
                />
              </DateInputContainer> */}
          <ButtonContainerModal>
            <CleanButton onPress={cleanDates} />
            <Button
              title="Continuar"
              isInactive={!(Object.values(modalDates))?.length
                  || initialHour?.length < 5 || endHour?.length < 5}
              onPress={handleDates}
            />
          </ButtonContainerModal>
          {/* </ScrollView> */}
        </DateContainer>
        {/* </ModalContainer> */}
      </Modalize>
    </>
  );
};
