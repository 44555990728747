import React, { useRef, useEffect } from 'react';
import { TextInput, TextInputProps } from 'react-native';

import {
  Container, Input, VisibleText, Box,
} from './styles';

interface Props extends TextInputProps {
  textValue: string;
  isErrored?: boolean;
}

export const VerificationCodeInput = ({ textValue, isErrored = false, ...rest }: Props) => {
  const inputRef = useRef<TextInput>(null);
  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const handlePress = () => {
    inputRef.current?.focus();
  };

  const isFocused = () => {
    if (inputRef.current?.isFocused()) return inputRef.current.isFocused();
    return false;
  };

  return (
    <Container
      onPress={handlePress}
      isFocused={isFocused()}
      isErrored={isErrored}
    >
      <Box>
        <VisibleText hasValue={!!textValue.charAt(0)}>{textValue.charAt(0) || 0}</VisibleText>
        <VisibleText hasValue={!!textValue.charAt(1)}>{textValue.charAt(1) || 0}</VisibleText>
        <VisibleText hasValue={!!textValue.charAt(2)}>{textValue.charAt(2) || 0}</VisibleText>
      </Box>
      <Box>
        <VisibleText hasValue={!!textValue.charAt(3)}>{textValue.charAt(3) || 0}</VisibleText>
        <VisibleText hasValue={!!textValue.charAt(4)}>{textValue.charAt(4) || 0}</VisibleText>
        <VisibleText hasValue={!!textValue.charAt(5)}>{textValue.charAt(5) || 0}</VisibleText>
      </Box>
      <Input
        ref={inputRef}
        maxLength={6}
        value={textValue}
        keyboardType="numeric"
        keyboardAppearance="dark"
        {...rest}
      />
    </Container>
  );
};
