import React from 'react';
import { CardStyleInterpolators, createStackNavigator } from '@react-navigation/stack';

import { FirstForm } from '../screens/SignUp/FirstForm';
import { SecondForm } from '../screens/SignUp/SecondForm';
import { LastForm } from '../screens/SignUp/LastForm';

const { Navigator, Screen } = createStackNavigator();

export const SignUpRoutes = () => (
  <Navigator screenOptions={{
    headerShown: false,
    cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
    detachPreviousScreen: false,
  }}
  >

    <Screen
      name="FirstForm"
      component={FirstForm}
    />
    <Screen
      name="SecondForm"
      component={SecondForm}
    />
    <Screen
      name="LastForm"
      component={LastForm}
      options={{
        gestureEnabled: false,
      }}
    />

  </Navigator>
);
