import React from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { useTheme } from 'styled-components/native';

import { Home } from '../screens/Home';
import { Search } from '../screens/Search';
import { UserRoutes } from './UserRoutes.routes';

import HomeIcon from '../assets/svg/HomeIcon.svg';
import UserIcon from '../assets/svg/UserIcon.svg';
import AdIcon from '../assets/svg/AdIcon.svg';

const { Navigator, Screen } = createBottomTabNavigator();

export const Logged = () => {
  const theme = useTheme();
  return (
    <Navigator screenOptions={{
      headerShown: false,
      tabBarStyle: {
        backgroundColor: theme.colors.secondaryBackground,
        borderTopWidth: 0,
        paddingTop: 11,
      },
      tabBarActiveTintColor: theme.colors.primary,
      tabBarInactiveTintColor: theme.colors.subtitle,
      tabBarLabelStyle: {
        fontFamily: theme.fonts.primaryRegular,
        fontSize: 10,
      },
    }}
    >

      <Screen
        name="Home"
        component={Home}
        options={{
          tabBarLabel: 'Início',
          tabBarIcon: ({ size, color }: {size: number, color: string}) => (
            <HomeIcon
              height={size}
              width={size}
              stroke={color}
            />
          ),
        }}
      />

      <Screen
        name="Search"
        component={Search}
        options={{
          tabBarLabel: 'Anúncio',
          tabBarIcon: ({ size, color }: {size: number, color: string}) => (
            <AdIcon
              height={size}
              width={size}
              stroke={color}
            />
          ),
        }}
      />

      <Screen
        name="Profile"
        component={UserRoutes}
        options={{
          tabBarLabel: 'Perfil',
          tabBarIcon: ({ size, color }: {size: number, color: string}) => (
            <UserIcon
              height={size}
              width={size}
              stroke={color}
            />
          ),
        }}
      />

    </Navigator>
  );
};
