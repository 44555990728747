import React from 'react';
import {
  Platform, ScrollView,
} from 'react-native';

import { Button } from '../../components/Button';
import { StatusBarBlur } from '../../components/StatusBarBlur';

import RestaurantImage from '../../assets/RestaurantImage.png';
import Menu from '../../assets/svg/Menu.svg';
import Phone from '../../assets/svg/Phone0.5.svg';
import Map from '../../assets/svg/Map.svg';
import Site from '../../assets/svg/Site.svg';

import {
  ActionContainer,
  ActionRowContainer,
  ActionTitle,
  ButtonContainer,
  CategoryText,
  Container,
  Header,
  HeaderButtonsContainer,
  HeaderContainer,
  HeaderContent,
  Icon,
  OptionsButtonContainer,
  RestaurantText,
} from './styles';
import { BackButton } from '../../components/BackButton';

export const Restaurant = () => (
  <Container>
    <ScrollView contentContainerStyle={{ flex: 1 }}>
      <HeaderContainer
        source={RestaurantImage}
        resizeMode="contain"
      >
        <Header start={{ x: 0.5, y: 1 }} end={{ x: 0.5, y: 0 }}>
          <HeaderButtonsContainer>
            <BackButton isActive />
            <OptionsButtonContainer>
              <Icon />
            </OptionsButtonContainer>
          </HeaderButtonsContainer>
          <HeaderContent>
            <CategoryText>Fast Food</CategoryText>
            <RestaurantText>Outback Steakhouse</RestaurantText>
            <ActionRowContainer>
              <ActionContainer>
                <Phone />
                <ActionTitle>Ligar</ActionTitle>
              </ActionContainer>
              <ActionContainer>
                <Map />
                <ActionTitle>Mapa</ActionTitle>
              </ActionContainer>
              <ActionContainer>
                <Site />
                <ActionTitle>Site</ActionTitle>
              </ActionContainer>
            </ActionRowContainer>
          </HeaderContent>
        </Header>
      </HeaderContainer>
    </ScrollView>
    <ButtonContainer>
      <Button
        title="Visualizar cardápio"
        leftIcon={<Menu style={{ marginRight: 11 }} />}
        isTextSpecial
      />
    </ButtonContainer>
    {Platform.OS === 'ios' && <StatusBarBlur />}
  </Container>
);
