import React, { ReactNode } from 'react';
import { ActivityIndicator, TouchableOpacityProps } from 'react-native';
import { useTheme } from 'styled-components/native';
import { Container, Title } from './styles';

interface Props extends TouchableOpacityProps {
  title: string;
  isInactive?: boolean;
  isLoading?: boolean;
  isInverted?: boolean;
  leftIcon?: ReactNode;
  isTextSpecial?: boolean;
  isOutline?: boolean;
}

export const Button = ({
  title,
  isInactive = false,
  isLoading = false, isInverted, leftIcon, isTextSpecial, isOutline, ...rest
}: Props) => {
  const theme = useTheme();

  return (
    <Container
      isInverted={isInverted}
      disabled={isInactive}
      isInactive={isInactive || isOutline}
      {...rest}
    >
      {leftIcon && leftIcon}
      {isLoading
        ? <ActivityIndicator size="small" color={theme.colors.title} />
        : (
          <Title
            isInverted={isInverted}
            isInactive={isInactive || isOutline}
            isTextSpecial={isTextSpecial}
          >
            {title}
          </Title>
        )}
    </Container>
  );
};
