import styled, { css } from "styled-components/native";
import { MaterialCommunityIcons } from '@expo/vector-icons';

interface Props {
  isActive: boolean
}

export const Container = styled.TouchableOpacity<Props>`
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border-width: 1.5px;
  border-color: ${({ theme, isActive }) => (isActive ? theme.colors.primary : theme.colors.subtitle)};

  
  ${({ theme, isActive }) => isActive && css`
    background: ${theme.colors.primary};
  `}
`;

export const Icon = styled(MaterialCommunityIcons)`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.title};
`;
