import React, { useState } from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';

import { Keyboard } from 'react-native';
import { BackButton } from '../../components/BackButton';

import PushIcon from '../../assets/svg/push.svg';
import BannerIcon from '../../assets/svg/bannerIcon.svg';

import {
  AdTypeText,
  Box, ButtonContainer, CampaignTitle, Container, Header, Main, Text, Title,
} from './styles';
import { AnimatedPlaceholderInput } from '../../components/inputs/AnimatedPlaceholderInput';
import { Button } from '../../components/Button';

export const GenericAd = () => {
  const { params } = useRoute();
  const adType = String(params) as 'banner' | 'push-notification';
  const [campaignName, setCampaignName] = useState('');
  const navigation = useNavigation();
  const getIcon = (value: 'banner' | 'push-notification') => {
    const data = {
      "push-notification": <PushIcon />,
      banner: <BannerIcon />,
    };
    return data[value];
  };

  const handlePress = () => {
    navigation.navigate('CampaignAd', {
      campaignName,
      adType,
    });
  };

  return (
    <Container onPress={Keyboard.dismiss}>
      <Header>
        <BackButton close />
        <Title>
          Anúncio
          {' '}
          {adType}
        </Title>
        <Box />
      </Header>
      <Main>
        {getIcon(adType)}
        <Text>Tipo de anuncio</Text>
        <AdTypeText>{adType === 'push-notification' ? 'Notificação' : 'Banner'}</AdTypeText>
      </Main>
      <CampaignTitle>Crie uma campanha</CampaignTitle>
      <AnimatedPlaceholderInput
        placeholder="Insira o nome da campanha"
        animatedValue={campaignName}
        onChangeText={setCampaignName}
      />
      <ButtonContainer>
        <Button title="Começar" isInactive={!campaignName} onPress={handlePress} />
      </ButtonContainer>
    </Container>
  );
};
