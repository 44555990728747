import React from 'react';
import { foodItemProps } from '../../screens/SignUp/LastForm';
import {
  Title, Container,
} from './styles';

interface Props extends foodItemProps {
  isActive: boolean;
  onPress: () => void;
}

export const FoodItem = ({
  name, isActive, onPress,
}: Props) => (
  <Container onPress={onPress} isActive={isActive}>
    <Title isActive={isActive}>{name}</Title>
  </Container>
);
