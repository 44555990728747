import styled from "styled-components/native";
import { RFValue } from "react-native-responsive-fontsize";
import { getStatusBarHeight } from "react-native-iphone-x-helper";

export const Container = styled.View`
  flex: 1;
  background: ${({ theme }) => theme.colors.background};
`;

export const HeaderContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.backgroundTitle};
  padding: ${getStatusBarHeight() + 24}px 20px 32px;
`;

export const Title = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primarySemiBold};
  color: ${({ theme }) => theme.colors.background};
  font-size: ${RFValue(20)}px;
`;

export const ActivityContainer = styled.View`
  padding: 50px 20px;
`;

export const ActivityTitle = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primarySemiBold};
  color: ${({ theme }) => theme.colors.title};
  font-size: ${RFValue(18)}px;
  margin-bottom: 32px;
`;

export const ActivityItemContainer = styled.View`
  margin-bottom: 32px;
`;
