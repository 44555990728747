import styled from "styled-components/native";
import { BlurView } from "expo-blur";

export const Container = styled(BlurView).attrs({
  intensity: 100,
  tint: 'dark',
})`
  width: 100%;
  height: 50px;
  position: absolute;
  z-index: 1;
`;
