import { getBottomSpace } from 'react-native-iphone-x-helper';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import MapView from 'react-native-maps';
import { RFValue } from 'react-native-responsive-fontsize';
import styled from 'styled-components/native';

export const Container = styled.View`
  flex: 1;
  background: ${({ theme }) => theme.colors.background};
`;

export const Header = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.colors.backgroundTitle};
  padding: 30px 20px;
`;

export const Title = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primarySemiBold};
  color: ${({ theme }) => theme.colors.background};
  font-size: ${RFValue(18)}px;
`;

export const RightBlock = styled.View`
  flex: 1;
`;

export const Content = styled(KeyboardAwareScrollView).attrs({
  contentContainerStyle: {
    justifyContent: 'space-between',
    flex: 1,
  },
})`
  padding: 32px 20px ${getBottomSpace() + 32}px;
`;

export const MultipleInputContainer = styled.Pressable`
  padding: 10px 20px 20px;
  background: ${({ theme }) => theme.colors.gray};
  border-radius: 8px;
`;

export const MultipleInputText = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primaryRegular};
  color: ${({ theme }) => theme.colors.subtitle};
  font-size: ${RFValue(12)}px;
`;

export const ButtonContainer = styled.View`
`;

export const MapContainer = styled(KeyboardAwareScrollView)``;

export const MapTitle = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primarySemiBold};
  color: ${({ theme }) => theme.colors.title};
  font-size: ${RFValue(16)}px;
  margin-bottom: 24px;
`;

export const MapInputContainer = styled.View`
  margin-bottom: 20px;
`;

export const Map = styled(MapView)`
  width: 100%;
  height: 272px;
  border-radius: 8px;
  margin-bottom: 50px;
`;

export const MultipleInput = styled.TextInput.attrs(({ theme }) => ({
  selectionColor: theme.colors.primary,
}))`
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.primaryRegular};
  color: ${({ theme }) => theme.colors.title};
  font-size: ${RFValue(14)}px;
  max-height: 300px;
`;
