import React, { useState } from 'react';
import { Feather } from '@expo/vector-icons';
import { useTheme } from 'styled-components/native';
import { useNavigation } from '@react-navigation/native';
import { Button } from '../../../components/Button';
import { BaseInput } from '../../../components/inputs/BaseInput';

import { EyeContainer } from '../../SignIn/SignInEmail/styles';
import {
  ButtonContainer,
  Container,
  Title,
  SubTitle,
} from '../FirstStep/styles';
import { InputContainer } from './styles';

export const LastStep = () => {
  const theme = useTheme();
  const [passwordInput, setPasswordInput] = useState('');
  const [passwordConfirmInput, setPasswordConfirmInput] = useState('');
  const [securePassword, setSecurePassword] = useState(true);
  const [secureConfirmPassword, setSecureConfirmPassword] = useState(true);
  const navigation = useNavigation();

  const handleEye = () => {
    setSecurePassword((oldState) => !oldState);
  };

  const handleEyeConfirmation = () => {
    setSecureConfirmPassword((oldState) => !oldState);
  };

  const handleCreateNewPassword = () => {
    navigation.navigate('Confirmation');
  };

  return (
    <Container>
      <Title>Criar uma nova senha</Title>
      <SubTitle>
        Crie uma nova senha com pelo
        {'\n'}
        menos 6 caracteres.
      </SubTitle>
      <InputContainer>
        <BaseInput
          placeholder="Senha"
          onChangeText={setPasswordInput}
          secureTextEntry={securePassword}
          rightIcon={(
            <EyeContainer onPress={handleEye}>
              <Feather name={securePassword ? "eye" : "eye-off"} size={20} color={theme.colors.subtitle} />
            </EyeContainer>
        )}
        />
      </InputContainer>
      <BaseInput
        placeholder="Confirmar senha"
        onChangeText={setPasswordConfirmInput}
        secureTextEntry={secureConfirmPassword}
        rightIcon={(
          <EyeContainer onPress={handleEyeConfirmation}>
            <Feather name={secureConfirmPassword ? "eye" : "eye-off"} size={20} color={theme.colors.subtitle} />
          </EyeContainer>
        )}
      />
      <ButtonContainer>
        <Button onPress={handleCreateNewPassword} title="Criar nova senha" isInactive={!passwordInput || !passwordConfirmInput} />
      </ButtonContainer>
    </Container>
  );
};
