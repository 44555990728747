import React, { useState } from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useForm, useWatch } from 'react-hook-form';
import { Feather } from '@expo/vector-icons';
import { useTheme } from 'styled-components/native';
import { AxiosError } from 'axios';
import { useAuth } from '../../../hooks/auth';

import { BackButton } from '../../../components/BackButton';
import { Button } from '../../../components/Button';
import { AnimatedPlaceholderInputForm } from '../../../components/inputs/AnimatedPlaceholderInputForm';

import QuestionBox from '../../../assets/svg/QuestionBox.svg';

import {
  ButtonContainer,
  Container,
  EyeContainer,
  Header,
  HelpContainer,
  HelpText,
  InputContainer,
  Title,
} from '../SignInEmail/styles';

interface Inputs {
  password: string;
}

export const SignInPassword = () => {
  const navigation = useNavigation();
  const { params } = useRoute();
  const { email } = params as { email: string };
  const theme = useTheme();
  const { SignIn } = useAuth();
  const [securePassword, setSecurePassword] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const { control, handleSubmit } = useForm();

  const inputsValues = useWatch({
    control,
  });

  const handleHelp = () => {
    navigation.navigate('Help');
  };

  const handleEye = () => {
    setSecurePassword((oldState) => !oldState);
  };

  const SignInForm = async (form: Inputs) => {
    setLoading(true);
    try {
      await SignIn({
        email,
        password: form.password,
      });
    } catch (e) {
      setError(e.detail);
      setLoading(false);
    }
  };

  return (
    <Container>
      <Header>
        <BackButton />
        <HelpContainer onPress={handleHelp}>
          <HelpText>Preciso de ajuda</HelpText>
          <QuestionBox />
        </HelpContainer>
      </Header>
      <Title>
        Digite
        {'\n'}
        sua senha
      </Title>
      <InputContainer>
        <AnimatedPlaceholderInputForm
          control={control}
          name="password"
          placeholder="Digite sua senha"
          secureTextEntry={securePassword}
          rightIcon={(
            <EyeContainer onPress={handleEye}>
              <Feather name={securePassword ? "eye" : "eye-off"} size={20} color={theme.colors.subtitle} />
            </EyeContainer>
          )}
          error={error}
        />
      </InputContainer>
      <ButtonContainer>
        <Button
          title="Entrar"
          isInactive={!inputsValues.password || isLoading}
          onPress={handleSubmit(SignInForm)}
          isLoading={isLoading}
        />
      </ButtonContainer>
    </Container>
  );
};
