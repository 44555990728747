import { MaterialIcons } from '@expo/vector-icons';
import { RFValue } from 'react-native-responsive-fontsize';
import styled from 'styled-components/native';

export const Container = styled.ScrollView`
  flex: 1;
  background: ${({ theme }) => theme.colors.background};
`;

export const ContainerImage = styled.TouchableOpacity`
  width: 100%;
  height: 231px;
  background: ${({ theme }) => theme.colors.gray};
  align-items: center;
  justify-content: center;
`;

export const Image = styled.Image`
  width: 100%;
  height: 100%;
`;

export const ImageText = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primaryMedium};
  font-size: ${RFValue(12)}px;
  color: ${({ theme }) => theme.colors.title};
  margin: 8px 0;
`;

export const ImageTextSub = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primaryRegular};
  font-size: ${RFValue(12)}px;
  color: ${({ theme }) => theme.colors.subtitle};
`;

export const SubContainer = styled.View`
  flex: 1;
  padding: 32px 20px;
`;

export const RestaurantTitle = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primarySemiBold};
  font-size: ${RFValue(18)}px;
  color: ${({ theme }) => theme.colors.title};
  margin-bottom: 10px;
`;

export const RestaurantSubTitle = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primaryRegular};
  font-size: ${RFValue(14)}px;
  color: ${({ theme }) => theme.colors.subtitle};
`;

export const RestaurantSubTitleButton = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primarySemiBold};
  font-size: ${RFValue(14)}px;
  color: ${({ theme }) => theme.colors.title};
`;

export const InfoContainer = styled.TouchableOpacity`
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.colors.gray100};
  width: 100%;
  flex-direction: row;
  padding: 24px 20px;
  align-items: center;
`;

export const InfoText = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primaryMedium};
  font-size: ${RFValue(12)}px;
  color: ${({ theme }) => theme.colors.title};
  margin-left: 14px;
  flex: 1;
`;

export const Icon = styled(MaterialIcons).attrs({
  name: 'chevron-right',
})`
  color: ${({ theme }) => theme.colors.subtitle};
  font-size: 24px;
`;

export const ButtonContainer = styled.View`
  padding: 0 20px;
  margin: 32px 0;
`;
