import { RFValue } from 'react-native-responsive-fontsize';
import styled from 'styled-components/native';

interface Props {
  isActive: boolean;
}

export const Container = styled.View.attrs({
  shadowOffset: {
    height: 0,
    width: 0,
  },
  shadowRadius: 8,
  shadowColor: 'rgba(42, 42, 62, 0.15)',
  shadowOpacity: 1,
  elevation: 5,
})`
  width: 100%;
  height: 214px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.background};
`;

export const ItemTitle = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primaryMedium};
  font-size: ${RFValue(16)}px;
  color: ${({ theme }) => theme.colors.title};
`;

export const Status = styled.View<Props>`
  height: 14px;
  width: 14px;
  border-radius: 7px;
  background: ${({ theme, isActive }) => (isActive ? theme.colors.primary : theme.colors.error)};
`;

export const Header = styled.View`
  padding: 24px 24px 30px 16px;
`;

export const SubContainer = styled.ScrollView`
`;

export const ItemStatusText = styled.Text`
  font-size: ${RFValue(12)}px;
  font-family: ${({ theme }) => theme.fonts.primaryRegular};
  color: ${({ theme }) => theme.colors.title};

`;

export const Block = styled.View`
  padding: 29px 0 33px 14px;
  height: 116px;
  width: 135px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.gray};
`;

export const ItemText = styled.Text`
  font-size: ${RFValue(14)}px;
  font-family: ${({ theme }) => theme.fonts.primaryRegular};
  color: ${({ theme }) => theme.colors.title};
`;

export const ItemTextValue = styled.Text`
  font-size: ${RFValue(18)}px;
  font-family: ${({ theme }) => theme.fonts.primarySemiBold};
  color: ${({ theme }) => theme.colors.title};
`;
