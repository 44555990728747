import styled, { css } from "styled-components/native";
import { MaterialIcons } from '@expo/vector-icons';

interface Props {
  isActive?: boolean;
}

interface iconProps {
  inverted: boolean;
}

export const Container = styled.TouchableOpacity<Props>`
  height: 48px;
  width: 48px;
  justify-content: center;
  align-items: center;
  border-radius: 24px;

  ${({ isActive, theme }) => isActive && css`
    background-color: ${theme.colors.secondaryBackground};
    padding-left: 5px;
  `}
`;

export const Icon = styled(MaterialIcons)<iconProps>`
  color: ${({ theme, inverted }) => (inverted ? theme.colors.background : theme.colors.title)};
  font-size: 24px;
`;
