import AsyncStorage from '@react-native-async-storage/async-storage';
import axios, { AxiosError } from 'axios';

export const api = axios.create({
  baseURL: 'https://menumax-cardapio-backend.herokuapp.com/v1',
});

// export const api = axios.create({
//   baseURL: 'http://192.168.1.10:5000/v1',
// });

export const googleApi = axios.create({
  baseURL: 'https://maps.googleapis.com/maps/api',
});

api.interceptors.response.use((config) => config,
  async (error: AxiosError) => {
    if (error.response?.status === 401) {
      try {
        const token = JSON.parse(await AsyncStorage.getItem('@MenuMax:refresh_token') || '');
        const { data: refreshData } = await api.post('/auth/login', {
          grant_type: 'refresh_token',
          refresh_token: token,
        });
        await AsyncStorage.setItem('@MenuMax:token', JSON.stringify(refreshData.access_token));
        await AsyncStorage.setItem('@MenuMax:refresh_token', JSON.stringify(refreshData.refresh_token));
        await AsyncStorage.setItem('@MenuMax:exp', JSON.stringify(refreshData.expires_in));
        api.defaults.headers.common.Authorization = `Bearer ${refreshData.access_token}`;
        return api(error.config);
      } catch {
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  });
