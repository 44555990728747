import { RFValue } from 'react-native-responsive-fontsize';
import styled from 'styled-components/native';

interface Props {
  type: 'banner' | 'push-notification';
}

export const Container = styled.View`
  flex-direction: row;
`;

export const Box = styled.View<Props>`
  height: 43px;
  width: 43px;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  background: ${({ theme, type }) => (type === 'banner' ? theme.colors.selected : theme.colors.selectedRed)};
`;

export const TextContainer = styled.View`
  margin: 0 16px;
  flex: 1;
`;

export const Title = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primaryMedium};
  font-size: ${RFValue(14)}px;
  color: ${({ theme }) => theme.colors.title};
`;

export const DateText = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primaryRegular};
  font-size: ${RFValue(12)}px;
  color: ${({ theme }) => theme.colors.subtitle};
`;
