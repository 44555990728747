import React, { useState } from 'react';
import { Button } from '../../components/Button';
import { GenericHeader } from '../../components/GenericHeader';
import { AnimatedPlaceholderInput } from '../../components/inputs/AnimatedPlaceholderInput';

import { Container, InputView } from './styles';

export const RestaurantSugestion = () => {
  const [restaurantName, setRestaurantName] = useState('');
  const [address, setAddress] = useState('');
  return (
    <Container>
      <GenericHeader title="Sugerir restaurantes" />
      <AnimatedPlaceholderInput
        placeholder="Nome do restaurante"
        animatedValue={restaurantName}
        onChangeText={setRestaurantName}
      />
      <InputView>
        <AnimatedPlaceholderInput
          placeholder="Endereço resumido"
          animatedValue={address}
          onChangeText={setAddress}
        />
      </InputView>
      <Button title="Sugerir restaurante" isInactive={!restaurantName || !address} />
    </Container>
  );
};
