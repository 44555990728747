import React, {
  createContext, ReactNode, useState, useContext, useCallback,
  useEffect,
} from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

interface Props {
  hasEntryBefore: boolean;
  setEntry: () => void;
  isLoading: boolean;
  hasShowedSplash: boolean;
  setShowedSplash: () => void;
}

interface ProviderProps {
  children: ReactNode;
}

const FirstEntry = createContext({} as Props);

export const FirstEntryProvider = ({ children }: ProviderProps) => {
  const [hasEntryBefore, setHasEntryBefore] = useState(false);
  const [hasShowedSplash, setHasShowedSplash] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const setEntry = useCallback(async () => {
    try {
      await AsyncStorage.setItem('@MenuMax:hasEntryBefore', JSON.stringify(true));
    } catch {
      throw new Error('Was not possible to set the value');
    } finally {
      setHasEntryBefore(true);
    }
  }, []);

  const getEntry = async () => {
    await AsyncStorage.setItem('@MenuMax:hasEntryBefore', JSON.stringify(false));
    try {
      const value = await AsyncStorage.getItem('@MenuMax:hasEntryBefore');
      if (value) setHasEntryBefore(JSON.parse(value));
    } catch {
      throw new Error('Was not possible to get the value');
    } finally {
      setIsLoading(false);
    }
  };

  const setShowedSplash = () => {
    setHasShowedSplash(true);
  };

  useEffect(() => {
    getEntry();
  }, []);

  return (
    <FirstEntry.Provider value={{
      hasEntryBefore, setEntry, isLoading, hasShowedSplash, setShowedSplash,
    }}
    >
      {children}
    </FirstEntry.Provider>
  );
};

export const useFirstEntry = () => {
  const context = useContext(FirstEntry);
  if (!context) throw new Error('Need to use Provider!');
  return context;
};
