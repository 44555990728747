import React, { ReactNode } from 'react';
import { Control, Controller } from 'react-hook-form';
import { TextInputMaskProps } from 'react-native-masked-text';
import { AnimatedPlaceholderMaskedInput } from '../AnimatedPlaceholderMaskedInput';

interface Props extends TextInputMaskProps {
  placeholder: string;
  name: string;
  control: Control;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  error?: string;
}

export const AnimatedPlaceholderMaskedInputForm = ({
  placeholder,
  name,
  control,
  leftIcon,
  rightIcon,
  error,
  ...rest
}: Props) => (
  <Controller
    control={control}
    name={name}
    render={({ field: { onChange, value } }) => (
      <AnimatedPlaceholderMaskedInput
        placeholder={placeholder}
        animatedValue={value}
        onChangeText={onChange}
        leftIcon={leftIcon}
        rightIcon={rightIcon}
        error={error}
        {...rest}
      />
    )}
  />
);
