import React, { useEffect, useState } from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import { getCurrentPositionAsync, useForegroundPermissions } from 'expo-location';
import { Marker } from 'react-native-maps';
import {
  ActivityIndicator, Alert, FlatList, Keyboard, Pressable,
} from 'react-native';
import { getDocumentAsync } from 'expo-document-picker';

import { BackButton } from '../../components/BackButton';
import { AnimatedPlaceholderMaskedInput } from '../../components/inputs/AnimatedPlaceholderMaskedInput';
import { Button } from '../../components/Button';
import { AnimatedPlaceholderInput } from '../../components/inputs/AnimatedPlaceholderInput';

import { EditProfileProps } from '../Profile';
import Flag from '../../assets/svg/BrasilFlag.svg';
import UserLocation from '../../assets/svg/UserLocation.svg';

import {
  ButtonContainer,
  Container,
  Content,
  Header,
  Map,
  MapContainer,
  MapInputContainer,
  MapTitle,
  MultipleInput,
  MultipleInputContainer,
  MultipleInputText,
  RightBlock,
  Title,
} from './styles';
import { api } from '../../services/axiosInstances';
import { useAuth } from '../../hooks/auth';
import { fileService, FileTypeEnum } from '../../services/fileService';
import { apiServices } from '../../services/apiServices';
import { SearchIcon, SectionContainer } from '../SignUp/LastForm/styles';
import { FoodItem } from '../../components/FoodItem';
import { BaseInput } from '../../components/inputs/BaseInput';
import { getCEPAsync } from '../../services/getCEPAsync';

export const EditProfile = () => {
  const navigation = useNavigation();
  const { params } = useRoute();
  const {
    user, updateUser, updateCompany, company,
  } = useAuth();
  const editParams = params as EditProfileProps;
  const [locationStatus, requestLocationPermission] = useForegroundPermissions();
  const [document, setDocument] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);

  const initialData = {
    address: company.masterLocation,
    categories: [],
    hour: company.openHourText,
    phone: company.phone,
    site: company.website,
    description: company.about,
    menu: null,
  };

  const [value, setValue] = useState(initialData[editParams.type]);
  const [filter, setFilter] = useState('');

  const handleTitle = () => {
    const data = {
      address: 'endereço',
      categories: 'categorias',
      hour: 'horários',
      phone: 'telefone',
      site: 'site',
      description: 'descrição',
      menu: 'cardápio',
    };
    return `Editar ${data[editParams.type]}`;
  };

  const handleCategory = (element: string) => {
    if (value.some((id) => id === element)) {
      setValue((oldState) => oldState.filter((id) => id !== element));
    } else {
      setValue((oldState) => [...oldState, element]);
    }
  };

  const handleEdit = async () => {
    const data = {
      address: async () => {
        await api.post('current-company/master-location', value);
        await apiServices
          .updateCurrentCompany({ ...company, masterLocation: value });
        updateCompany({
          ...company,
          masterLocation: {
            ...value,
          },
        });
      },
      categories: async () => {
        await api.post('company-foods', {
          foodsId: value,
        });
      },
      hour: async () => {
        const updateCompanyResult = await apiServices
          .updateCurrentCompany({ ...company, openHourText: value });

        if (!updateCompanyResult.success) {
          Alert.alert('Erro', updateCompanyResult.detail);
          return;
        }

        updateCompany(updateCompanyResult.data);
      },
      phone: async () => {
        const updateCompanyResult = await apiServices
          .updateCurrentCompany({ ...company, phone: value });

        if (!updateCompanyResult.success) {
          Alert.alert('Erro', updateCompanyResult.detail);
          return;
        }

        updateCompany(updateCompanyResult.data);
      },
      site: async () => {
        const updateCompanyResult = await apiServices
          .updateCurrentCompany({ ...company, website: value });

        if (!updateCompanyResult.success) {
          Alert.alert('Erro', updateCompanyResult.detail);
          return;
        }
        updateCompany(updateCompanyResult.data);
      },
      description: async () => {
        const updateCompanyResult = await apiServices
          .updateCurrentCompany({ ...company, about: value });

        if (!updateCompanyResult.success) {
          Alert.alert('Erro', updateCompanyResult.detail);
          return;
        }

        updateCompany(updateCompanyResult.data);
        updateUser({ ...user, about: value });
      },
      menu: async () => {
        setIsLoading(true);
        const documentReceived = await getDocumentAsync({ type: 'application/pdf' });
        if (documentReceived.type === 'success') {
          const result = await fileService.sendFile(
            documentReceived,
            FileTypeEnum.Menu,
          );
          if (!result.success) return Alert.alert("Error", result.detail);

          const updatedCompanyResult = await apiServices
            .updateCurrentCompany({ ...company, menuId: result.data.fileId });

          if (!updatedCompanyResult.success) return Alert.alert("Error", "Não foi possível atualizar o cardápio");
          updateCompany({
            ...company, ...updatedCompanyResult.data, menuId: result.data.fileId, menu: result.data,
          });

          setDocument(result.data.url);
        }
      },
    };
    setIsLoading(true);
    try {
      await data[editParams.type]();
    } catch {
      Alert.alert('Ocorreu um erro', 'tente novamente mais tarde');
    } finally {
      setIsLoading(false);
      navigation.goBack();
    }
  };

  const renderContent = () => {
    const data = {
      address: (
        <MapContainer showsVerticalScrollIndicator={false}>
          <Pressable onPress={Keyboard.dismiss}>
            <MapTitle>Local do seu negócio</MapTitle>
            <MapInputContainer>
              <AnimatedPlaceholderMaskedInput
                type="zip-code"
                animatedValue={value?.zip}
                onChangeText={(e) => setValue((oldState) => ({ ...oldState, zip: e }))}
                placeholder="CEP"
              />
            </MapInputContainer>
            <MapInputContainer>
              <AnimatedPlaceholderInput
                animatedValue={value?.country}
                onChangeText={(e) => setValue((oldState) => ({ ...oldState, country: e }))}
                placeholder="País/Região"
              />
            </MapInputContainer>
            <MapInputContainer>
              <AnimatedPlaceholderInput
                animatedValue={value?.address}
                onChangeText={(e) => setValue((oldState) => ({ ...oldState, address: e }))}
                placeholder="Endereço"
              />
            </MapInputContainer>
            <MapInputContainer>
              <AnimatedPlaceholderInput
                animatedValue={value?.city}
                onChangeText={(e) => setValue((oldState) => ({ ...oldState, city: e }))}
                placeholder="Cidade"
              />
            </MapInputContainer>
            <MapInputContainer>
              <AnimatedPlaceholderInput
                animatedValue={value?.state}
                onChangeText={(e) => setValue((oldState) => ({ ...oldState, state: e }))}
                placeholder="Estado"
              />
            </MapInputContainer>
            <MapInputContainer>
              <AnimatedPlaceholderInput
                animatedValue={value?.number}
                onChangeText={(e) => setValue((oldState) => ({ ...oldState, number: e }))}
                placeholder="Número"
              />
            </MapInputContainer>

            {
          value?.latitude
            ? (
              <Map
                loadingEnabled
                initialRegion={{
                  latitude: value?.latitude,
                  longitude: value?.longitude,
                  latitudeDelta: 0.003,
                  longitudeDelta: 0.002,
                }}
              >
                <Marker
                  coordinate={{
                    latitude: value?.latitude,
                    longitude: value?.longitude,
                  }}
                  draggable
                  onDragEnd={({ nativeEvent: { coordinate } }) => {
                    setValue((oldState) => ({
                      ...oldState,
                      latitude: coordinate.latitude,
                      longitude: coordinate.longitude,
                    }));
                  }}
                >
                  <UserLocation />
                </Marker>
              </Map>
            )
            : (
              <ActivityIndicator />
            )
          }
          </Pressable>
        </MapContainer>
      ),
      categories: (
        <>
          <BaseInput
            onChangeText={setFilter}
            placeholder="Procurar categoria"
            leftIcon={<SearchIcon />}
            value={filter}
          />
          <FlatList
            showsVerticalScrollIndicator={false}
            scrollEventThrottle={16}
            data={categories}
            keyExtractor={(item) => item?.foodId}
            contentContainerStyle={{
              paddingVertical: 32,
              paddingBottom: 50,
            }}
            renderItem={({ item }) => (
              <SectionContainer>
                <FoodItem
                  name={item.name}
                  isActive={value.some((element) => element === item.foodId)}
                  onPress={() => handleCategory(item.foodId)}
                />
              </SectionContainer>
            )}
          />
        </>
      ),
      hour: (
        <MultipleInputContainer>
          <MultipleInputText>Horários de funcionamento</MultipleInputText>
          <MultipleInput
            multiline
            value={value}
            onChangeText={setValue}
          />
        </MultipleInputContainer>
      ),
      phone: (
        <AnimatedPlaceholderMaskedInput
          animatedValue={value}
          onChangeText={setValue}
          placeholder="Número do telefone"
          type="cel-phone"
          leftIcon={<Flag />}
        />
      ),
      site: (
        <AnimatedPlaceholderInput
          animatedValue={value}
          onChangeText={setValue}
          placeholder="Site"
        />
      ),
      description: (
        <MultipleInputContainer>
          <MultipleInputText>Descrição</MultipleInputText>
          <MultipleInput
            multiline
            value={value}
            onChangeText={setValue}
          />
        </MultipleInputContainer>
      ),
      menu: (
        <>
          <Button
            title={document ? "Cardápio escolhido" : "Escolher cardápio"}
            onPress={handleEdit}
          />
        </>
      ),
    };
    return data[editParams.type];
  };

  useEffect(() => {
    (async () => {
      if (value?.zip.length > 8) {
        const formattedCep = value.zip.replace('-', '');
        const result = await getCEPAsync(formattedCep);
        setValue((oldState) => ({
          ...oldState,
          city: result.localidade,
          address: `${result.logradouro}, ${result.bairro}`,
          state: result.uf,
        }));
      }
    })();
  }, [value?.zip]);

  useEffect(() => {
    (async () => {
      if (editParams.type === 'address') {
        if (locationStatus?.granted) return;
        await requestLocationPermission();
        if (!value?.latitude) {
          const { coords } = await getCurrentPositionAsync();
          setValue((oldState) => ({
            ...oldState,
            latitude: coords.latitude,
            longitude: coords.longitude,
          }));
        }
      }
      if (editParams.type === 'categories') {
        try {
          const { data } = await api.get('/foods');
          const { data: companyFoods } = await api.get<{ result: { foodId: string}[]}>('/company-foods');
          setCategories(data.result);
          setValue(
            companyFoods.result.reduce((atual, vindo) => {
              atual.foodsId.push(vindo.foodId);
              return atual;
            }, { foodsId: [] }).foodsId,
          );
        } catch {
          Alert.alert('Não conseguimos no momento', 'tente novamente mais tarde :/');
        }
      }
    })();
  }, [editParams.type, locationStatus?.granted, requestLocationPermission, value?.latitude]);

  useEffect(() => {
    (async () => {
      const { data } = await api.get(`/foods?Name=${filter}`);
      setCategories(data.result);
    })();
  }, [filter]);

  return (
    <Container>
      <Header>
        <BackButton inverted />
        <Title>
          {handleTitle()}
        </Title>
        <RightBlock />
      </Header>
      <Content>
        {renderContent()}
        <ButtonContainer>
          <Button
            title="Salvar"
            isInactive={(!value && !document) || isLoading}
            isLoading={isLoading}
            onPress={handleEdit}
          />
        </ButtonContainer>
      </Content>
    </Container>
  );
};
