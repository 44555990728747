import { RFValue } from "react-native-responsive-fontsize";
import { TextInputMask } from 'react-native-masked-text';
import styled, { css } from "styled-components/native";

interface Props {
  isFocused: boolean;
  isErrored: boolean;
}

interface InputProps {
  hasLeftIcon: boolean;
}

export const Container = styled.View<Props>`
  width: 100%;
  height: 59px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.secondaryBackground};
  padding: 0 20px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-color: transparent;


  border-width: 1px;
  ${({ isErrored, theme }) => isErrored && css`
    border-color: ${theme.colors.error};
  `}
  ${({ isFocused, theme }) => isFocused && css`
    border-color: ${theme.colors.primary};
  `}
`;

export const Input = styled(TextInputMask)<InputProps>`
  flex: 1;
  height: 50px;
  top: 10px;

  font-family: ${({ theme }) => theme.fonts.primaryMedium};
  color: ${({ theme }) => theme.colors.title};
  font-size: ${RFValue(14)}px;

  ${({ hasLeftIcon }) => hasLeftIcon && css`
    margin-left: 16px;
  `}
`;

export const ErrorText = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primaryRegular};
  color: ${({ theme }) => theme.colors.error};
  font-size: ${RFValue(12)}px;
  margin: 16px 0 0;
`;
