import { MaterialIcons } from '@expo/vector-icons';
import { getBottomSpace } from 'react-native-iphone-x-helper';
import { RFValue } from 'react-native-responsive-fontsize';
import styled, { css } from 'styled-components/native';

interface ModalCheck {
  checked?: boolean;
}

export const ModalContainer = styled.Pressable`
  flex: 1;
  background: rgba(0, 0, 0, 0.5);
  justify-content: flex-end;
`;

export const ModalContent = styled.View`
  background: ${({ theme }) => theme.colors.background};
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`;

export const ModalHeader = styled.View`
  padding: 30px 15px;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.colors.subtitle};
`;

export const ModalTitle = styled.Text`
  font-family: ${({ theme }) => theme.fonts.primarySemiBold};
  color: ${({ theme }) => theme.colors.subtitle};
  font-size: ${RFValue(18)}px;
`;

export const ModalIcon = styled(MaterialIcons).attrs({
  name: 'close',
})`
  font-size: 32px;
  color: ${({ theme }) => theme.colors.subtitle};
`;

export const CenterView = styled.View`
  width: 20px;
`;

export const ModalDatesContainer = styled.View`
  padding-bottom: ${getBottomSpace() + 24}px;
`;

export const ItemContainer = styled.TouchableOpacity.attrs({
  activeOpacity: 0.8,
})<ModalCheck>`
  flex-direction: row;
  padding: 16px 20px;
  justify-content: space-between;

  ${({ theme, checked }) => checked && css`
    background: ${theme.colors.selected};
  `}
`;

export const ItemTitle = styled.Text<ModalCheck>`
  font-family: ${({ theme }) => theme.fonts.primaryMedium};
  color: ${({ theme, checked }) => (checked ? theme.colors.primary : theme.colors.subtitle)};
  font-size: ${RFValue(16)}px;
`;

export const ItemBox = styled.View`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.subtitle};
  align-items: center;
  justify-content: center;
`;

export const ItemCheck = styled.View`
  width: 65%;
  height: 65%;
  border-radius: 20px;
  background: ${({ theme }) => theme.colors.primary};
`;
