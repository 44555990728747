import React from 'react';
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';

import {
  Bar, Container, ProgressText, ProgressTextContainer, ContentBar, Dots,
} from './styles';

interface Props {
  progress: number;
  steps: string[];
  navigation: (value: number) => void;
}

const AnimatedContentBar = Animated.createAnimatedComponent(ContentBar);

export const ProgressBar = ({ progress, steps, navigation }: Props) => {
  const fatherBar = useSharedValue(0);
  const isActive = (value: number) => {
    if (progress < 0.5 && value === 1) return true;
    if (progress >= 0.5 && progress < 1 && value === 2) return true;
    if (progress === 1 && value === 3) return true;
    return false;
  };

  const rStyle = useAnimatedStyle(() => ({
    width: withTiming(progress * fatherBar.value),
  }));

  return (
    <Container>
      <ProgressTextContainer>
        {steps.map((item, index) => (
          <ProgressText
            key={item}
            isActive={isActive(index + 1)}
            onPress={() => navigation(index)}
          >
            {item}

          </ProgressText>
        ))}
      </ProgressTextContainer>
      <Bar onLayout={(e) => { fatherBar.value = (e.nativeEvent.layout.width); }}>
        <AnimatedContentBar style={rStyle} />
        {steps.map((item, index) => (
          <Dots key={item} isActive={isActive(index + 1)} onPress={() => navigation(index)} />
        ))}
      </Bar>
    </Container>
  );
};
